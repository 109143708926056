import React from "react";
import {Route} from "react-router-dom";
import {CustomersLoadingDialog} from "./customers-loading-dialog/CustomersLoadingDialog";
import {CustomerEditDialog} from "./customer-edit-dialog/CustomerEditDialog";
import {CustomerDeleteDialog} from "./customer-delete-dialog/CustomerDeleteDialog";
import {CustomersDeleteDialog} from "./customers-delete-dialog/CustomersDeleteDialog";
import {CustomersFetchDialog} from "./customers-fetch-dialog/CustomersFetchDialog";
import {CustomersUpdateStateDialog} from "./customers-update-status-dialog/CustomersUpdateStateDialog";
import {CustomersUIProvider} from "./CustomersUIContext";
import {CustomersCard} from "./CustomersCard";
import {MakeOrder} from "./MakeOrder";

export function OrdersPage({history}) {
    const customersUIEvents = {
        newCustomerButtonClick: () => {
            history.push("/sales/orders/new");
        },
        openEditCustomerDialog: (id) => {
            history.push(`/sales/orders/${id}/edit`);
        },
        openDeleteCustomerDialog: (id) => {
            history.push(`/sales/orders/${id}/delete`);
        },
        openDeleteCustomersDialog: () => {
            history.push(`/sales/orders/deleteCustomers`);
        },
        openFetchCustomersDialog: () => {
            history.push(`/sales/orders/fetch`);
        },
        openUpdateCustomersStatusDialog: () => {
            history.push("/sales/orders/updateStatus");
        }
    }


    return (
        <CustomersUIProvider customersUIEvents={customersUIEvents}>
            <CustomersLoadingDialog/>
            {history.location.pathname.includes('edit') && <Route path="/sales/orders/:id/edit">
                {({history, match}) => (
                    <MakeOrder
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push("/sales/orders");
                        }}
                    />
                )}
            </Route>}

            <Route path="/sales/orders/deleteCustomers">
                {({history, match}) => (
                    <CustomersDeleteDialog
                        show={match != null}
                        onHide={() => {
                            const auxLocation = window.location.href.includes("Save");
                            history.push( auxLocation ? "/sales/ordersSave" : "/sales/orders");
                        }}
                    />
                )}
            </Route>
            <Route path="/sales/orders/:id/delete">
                {({history, match}) => (
                    <CustomerDeleteDialog
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            const auxLocation = window.location.href.includes("Save");
                            history.push( auxLocation ? "/sales/ordersSave" : "/sales/orders");
                        }}
                    />
                )}
            </Route>
            <Route path="/sales/orders/fetch">
                {({history, match}) => (
                    <CustomersFetchDialog
                        show={match != null}
                        onHide={() => {
                            const auxLocation = window.location.href.includes("Save");
                            history.push( auxLocation ? "/sales/ordersSave" : "/sales/orders");
                        }}
                    />
                )}
            </Route>
            <Route path="/sales/orders/updateStatus">
                {({history, match}) => (
                    <CustomersUpdateStateDialog
                        show={match != null}
                        onHide={() => {
                            const auxLocation = window.location.href.includes("Save");
                            history.push( auxLocation ? "/sales/ordersSave" : "/sales/orders");
                        }}
                    />
                )}
            </Route>
            {history.location.pathname !== '/sales/orders/new' && !history.location.pathname.includes('edit') && <CustomersCard/>}
        </CustomersUIProvider>
    );
}
