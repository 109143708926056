import React, {useEffect, useMemo, useState} from "react";
import {
    Card,
    CardBody,
    CardHeader, DatePickerField,
} from '../../../../../_metronic/_partials/controls';
import {Input as InputA, AutoComplete, Select, Upload, Modal} from 'antd';
import {ConfigProvider, Input as InputAnt} from 'antd';
import 'moment/locale/es-mx';

import {makeStyles, useTheme} from '@material-ui/core';
import {Stepper} from '@material-ui/core';
import {Step} from '@material-ui/core';
import {StepLabel} from '@material-ui/core';
import {Button} from '@material-ui/core';
import {Typography} from '@material-ui/core';
import {TextField} from '@material-ui/core';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import * as actions from '../../_redux/users/usersActions';
import { DatePicker, Space } from 'antd';
import locale from 'antd/es/locale/es_ES';
import locale2 from 'antd/es/date-picker/locale/es_ES';

import * as actions1 from "../../_redux/users/usersActions";
import * as actions2 from "../../_redux/customers/customersActions";
import * as actionsO from "../../_redux/orders/ordersActions";
import {PlusSquareTwoTone, UploadOutlined} from "@ant-design/icons";
import moment from "moment";
import {Option} from "antd/es/mentions";
import TextArea from "antd/es/input/TextArea";
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";


const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));
const useStyles2 = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
}));

function getSteps() {
    return ['Datos personales', 'Dirección', 'Verifica los datos','Paga KIT inicial'];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return 'Introduce sus datos personales';
        case 1:
            return 'Introduce su dirección';
        case 2:
            return 'KIT inicial';
        case 3:
            return 'Verifica los datos';
        default:
            return 'Error paso no identificado';
    }
}


export function InviteProcess({teamInvite}) {
    const classes = useStyles();
    const classes2 = useStyles2();
    const dispatch = useDispatch();
    const dataAuth = useSelector(state => state.auth);

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    // const customersUIContext = useCustomersUIContext();
    const [teamRegister, setTeamRegister] = React.useState(false);
    const [stepTwo, setStepTwo] = React.useState(false);
    const [currentError, setCurrentError] = React.useState(null);
    const [currentDetails, setCurrentDetails] = React.useState({date:null,comments:null,phoneNumber:null,name:null,paymentMethod:null});
    const [currentAddress, setCurrentAddress] = React.useState(null);

    const theme1 = useTheme();

    const currentListAddress = useSelector(
        (state) => (state.users.addressOrder),
        shallowEqual
    );
    // const customersUIProps = useMemo(() => {
    //   return {
    //     ids: customersUIContext.ids,
    //     newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
    //   };
    // }, [customersUIContext]);

    function randomString(length) {
        let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }


    const saveUser = () => {
        let rPassword = randomString(9 );

        let userToSend = {
            name:values.name,
            last_name_1:values.last_name_1,
            last_name_2:values.last_name_2,
            email:values.email,
            genre:values.genre,
            birthday:'2020-09-02',
            street:values.street,
            state:values.state,
            type:1,
            city:values.city,
            latitude:null,
            longitude:null,
            postal_code:values.postal_code,
            colony:values.colony,
            num_int:values.num_int,
            phone:values.phone,
            num_ext:values.num_ext,
            user_above:dataAuth.user.user_id,
            password1: rPassword,
            password2: rPassword,
            team_invite:teamInvite
        };

        dispatch(actions.createUser(userToSend)).then(() => {
            console.log("update data")
        });


    };

    const [values, setValues] = React.useState({
        name:"",
        last_name_1:"",
        last_name_2:"",
        email:"",
        genre:"",
        phone:"",
        type_user_id:"",
        type:"",
        dateOfBirth:"",
        birthday:"",
        street:"",
        state:"",
        city:"",
        postal_code:"",
        colony:"",
        num_int:"",
        num_ext:"",
        address_id:"",
        user_id:""
    });


    const [address, setAddress] = React.useState([{
        street:"",
        state:"",
        city:"",
        postal_code:"",
        colony:"",
        num_int:"",
        num_ext:"",
        address_id:"",
    }]);

    function handleNext() {
        if(activeStep === 0){
            if(values.name === '' || values.dateOfBirth === ''){
                setCurrentError('Tienes que llenar todos los datos');
                return false
            }
        }else if(activeStep === 1){
            if(values.street === '' || values.postal_code === ''){
                setCurrentError('Tienes que llenar todos los datos');
                return false
            }
        }if(activeStep === 3){
            saveUser();
        }
        setCurrentError(null);
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };


    const handleChangeAddress = name => event => {
        const auxAddress = [...address];
        auxAddress[address.length - 1][name] = event.target.value;
        setAddress(auxAddress);
    };

    const handleChangeSelect = (name,value) => {
        setValues({ ...values, [name]: value });
    };

    const handleChangeSelectAddress = (name,value) => {
        const auxAddress = [...address];
        auxAddress[address.length - 1][name] = value;
        setAddress(auxAddress);
    };

    function disabledDate(current) {
        // Can not select days before today and today
        let format1 = 'hh:mm:ss'
        let beforeTime = moment('00:00:00', format1);
        let afterTime = moment('15:00:00', format1);
        current.format('D');
        let auxNumber = moment().isBetween(beforeTime, afterTime) ? 1 : 2;
        return current && (current.dayOfYear() < moment().endOf('day').dayOfYear() + auxNumber || moment(current).day() === 0 || moment(current).day() === 6 || (moment(current).day() === 1 && current.dayOfYear() - 2 <= moment().endOf('day').dayOfYear() && (moment().endOf('day').day() === 5 || moment().endOf('day').day() === 6 )));
    }

    function range(start, end) {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }

    function disabledDateTime() {
        return {
            disabledHours: () => range(0, 10).concat(range(17, 24))
        };
    }

    const onChange = (date, dateString) => {
        setValues({ ...values, ['dateOfBirth']: dateString });
    };

    const estados = [
        'Álvaro Obregón',
        'Azcapotzalco',
        'Benito Juárez',
        'Coyoacán',
        'Cuajimalpa de Morelos',
        'Cuauhtémoc',
        'Gustavo A. Madero',
        'Iztacalco',
        'Iztapalapa',
        'Magdalena Contreras',
        'Miguel Hidalgo',
        'Milpa Alta',
        'Tláhuac',
        'Tlalpan',
        'Venustiano Carranza',
        'Xochimilco'
    ]

    const municipios = [
        'Atizapan De Zaragoza',
        'Chalco',
        'Chimalhuacan',
        'Coacalco',
        'Cuahutitlan Izcalli',
        'Ecatepec',
        'Heroes De Tecamac',
        'Huixquilucan',
        'Isodro Fabela',
        'Ixtapaluca',
        'Jilotzingo',
        'La Paz',
        'Naucalpan',
        'Nezahualcoyolt',
        'Nicolas Romero',
        'Tlanepantla',
        'Toluca',
        'Tultitlan',
        'Valle De Chalco',
        'Otro',
    ]

    function handleReset() {
        setTeamRegister(false);
        setStepTwo(false);
        setActiveStep(0);
        setValues({
            name:"",
            last_name_1:"",
            last_name_2:"",
            email:"",
            genre:"",
            phone:"",
            type_user_id:"",
            type:"",
            dateOfBirth:"",
            birthday:"",
            street:"",
            state:"",
            city:"",
            postal_code:"",
            colony:"",
            num_int:"",
            num_ext:"",
            address_id:"",
            user_id:""
        })
    }

    useEffect(() => {
        if (!dataAuth.user.admin_user_id) {
            dispatch(actions1.fetchUserAddress({user_id: dataAuth.user.user_id}));
        }
    }, [])

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [firstOpen, setFirstOpen] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const [fileList, setFileList] = useState([]);

    console.log(fileList)
    const props = {
        beforeUpload: file => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };

    return (
        <>
            <Card>
                <CardHeader title="Invitar usuarios" />
                <CardBody>
                    <Modal v-if={isModalVisible} title="Agregar dirección" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <form className={classes2.container} noValidate autoComplete="off">
                            <div className="row">
                                <div className="col-12">
                                    <Select
                                        onSelect={(value) => {
                                            handleChangeSelectAddress('state',value)
                                        }}
                                        className={classes2.textField}
                                        style={{width: '100%', marginTop: '10px'}}
                                        placeholder={"Estado"}
                                    >
                                        <Option value={'CDMX'}>
                                            CDMX
                                        </Option>
                                        <Option value={'Estado de México'}>
                                            Estado de México
                                        </Option>
                                        <Option value={'Queretaro'}>
                                            Queretaro
                                        </Option>
                                        <Option value={'Morelia'}>
                                            Morelia
                                        </Option>
                                        <Option value={'Cuernavaca'}>
                                            Cuernavaca
                                        </Option>
                                    </Select>
                                    {
                                        values.state === 'CDMX' || values.state === 'Estado de México' ? <Select
                                                onSelect={(value) => {
                                                    handleChangeSelectAddress('city',value)
                                                }}
                                                className={classes2.textField}
                                                style={{width: '100%', marginTop: '10px'}}
                                                placeholder={values.state === 'CDMX' ? 'Seleccione Alcaldía' : 'Seleccione Municipio'}
                                            >
                                                {(values.state === 'CDMX' ? estados : municipios).map((estado) => {
                                                    return <Option value={estado}>
                                                        {estado}
                                                    </Option>
                                                })}
                                            </Select>
                                            : <TextField
                                                id="outlined-name"
                                                label="Municipio/alcaldia"
                                                className={classes2.textField}
                                                value={address[address.length - 1].city}
                                                style={{width: '100%'}}
                                                onChange={handleChangeAddress('city')}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                    }

                                    <TextField
                                        id="outlined-name"
                                        label="Codigo Postal"
                                        className={classes2.textField}
                                        value={address[address.length - 1].postal_code}
                                        onChange={handleChangeAddress('postal_code')}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        id="outlined-name"
                                        label="Colonia"
                                        className={classes2.textField}
                                        value={address[address.length - 1].colony}
                                        onChange={handleChangeAddress('colony')}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        id="outlined-name"
                                        label="Calle"
                                        className={classes2.textField}
                                        value={address[address.length - 1].street}
                                        onChange={handleChangeAddress('street')}
                                        margin="normal"
                                        variant="outlined"
                                    />

                                    <TextField
                                        id="outlined-name"
                                        label="Numero interno"
                                        className={classes2.textField}
                                        value={address[address.length - 1].num_int}
                                        onChange={handleChangeAddress('num_int')}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        id="outlined-name"
                                        label="Numero externo"
                                        className={classes2.textField}
                                        value={address[address.length - 1].num_ext}
                                        onChange={handleChangeAddress('num_ext')}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        </form>
                    </Modal>
                    <div className={classes.root}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map(label => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <div>
                            {activeStep === steps.length ? (
                                <div style={{margin:"0 auto",display:"block"}}>
                                    <Typography style={{textAlign:"center"}} className={classes.instructions}>Registrado</Typography>
                                    <Button style={{margin:"0 auto",display:"block"}} onClick={handleReset}>Reiniciar</Button>
                                </div>
                            ) : (
                                <>
                                    {activeStep === 0  && <form className={classes2.container} noValidate autoComplete="off">
                                        <div className="row">
                                            <div className="col-xsl-12">
                                                <TextField
                                                    id="outlined-name"
                                                    label="Nombre(s)"
                                                    className={classes2.textField}
                                                    value={values.name}
                                                    onChange={handleChange('name')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    id="outlined-name"
                                                    label="Apellido paterno"
                                                    className={classes2.textField}
                                                    value={values.last_name_1}
                                                    onChange={handleChange('last_name_1')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    id="outlined-name"
                                                    label="Apellido materno"
                                                    className={classes2.textField}
                                                    value={values.last_name_2}
                                                    onChange={handleChange('last_name_2')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />

                                                <TextField
                                                    id="outlined-name"
                                                    label="Número de telefono"
                                                    className={classes2.textField}
                                                    value={values.phone}
                                                    onChange={handleChange('phone')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    id="outlined-name"
                                                    label="Email"
                                                    className={classes2.textField}

                                                    value={values.email}
                                                    onChange={handleChange('email')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <ConfigProvider locale={locale}>
                                                    <DatePicker
                                                        style={{width:"100%", height:'50px', marginTop:"10px"}}
                                                        onChange={onChange}
                                                        placeholder="Fecha de nacimiento"
                                                        className={classes2.textField} />
                                                </ConfigProvider>

                                                {/*<TextField*/}
                                                {/*    id="outlined-name"*/}
                                                {/*    label="Fecha de nacimiento"*/}
                                                {/*    className={classes2.textField}*/}
                                                {/*    value={values.dateOfBirth}*/}
                                                {/*    onChange={handleChange('dateOfBirth')}*/}
                                                {/*    margin="normal"*/}
                                                {/*    variant="outlined"*/}
                                                {/*/>*/}
                                            </div>
                                        </div>
                                    </form>}
                                    {activeStep === 1  && <form className={classes2.container} noValidate autoComplete="off">
                                        <div className="row">
                                            <div className="col-12">
                                                <Select
                                                    onSelect={(value) => {
                                                        handleChangeSelect('state',value)
                                                    }}
                                                    className={classes2.textField}
                                                    style={{width: '100%', marginTop: '10px'}}
                                                    placeholder={"Estado"}
                                                >
                                                    <Option value={'CDMX'}>
                                                        CDMX
                                                    </Option>
                                                    <Option value={'Estado de México'}>
                                                        Estado de México
                                                    </Option>
                                                    <Option value={'Queretaro'}>
                                                        Queretaro
                                                    </Option>
                                                    <Option value={'Morelia'}>
                                                        Morelia
                                                    </Option>
                                                    <Option value={'Cuernavaca'}>
                                                        Cuernavaca
                                                    </Option>
                                                </Select>
                                                {
                                                    values.state === 'CDMX' || values.state === 'Estado de México' ? <Select
                                                            onSelect={(value) => {
                                                                handleChangeSelect('city',value)
                                                            }}
                                                            className={classes2.textField}
                                                            style={{width: '100%', marginTop: '10px'}}
                                                            placeholder={values.state === 'CDMX' ? 'Seleccione Alcaldía' : 'Seleccione Municipio'}
                                                        >
                                                            {(values.state === 'CDMX' ? estados : municipios).map((estado) => {
                                                                return <Option value={estado}>
                                                                    {estado}
                                                                </Option>
                                                            })}
                                                        </Select>
                                                        : <TextField
                                                            id="outlined-name"
                                                            label="Municipio/alcaldia"
                                                            className={classes2.textField}
                                                            value={values.city}
                                                            style={{width: '100%'}}
                                                            onChange={handleChange('city')}
                                                            margin="normal"
                                                            variant="outlined"
                                                        />
                                                }

                                                <TextField
                                                    id="outlined-name"
                                                    label="Codigo Postal"
                                                    className={classes2.textField}
                                                    value={values.postal_code}
                                                    onChange={handleChange('postal_code')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    id="outlined-name"
                                                    label="Colonia"
                                                    className={classes2.textField}
                                                    value={values.colony}
                                                    onChange={handleChange('colony')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    id="outlined-name"
                                                    label="Calle"
                                                    className={classes2.textField}
                                                    value={values.street}
                                                    onChange={handleChange('street')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />

                                                <TextField
                                                    id="outlined-name"
                                                    label="Numero interno"
                                                    className={classes2.textField}
                                                    value={values.num_int}
                                                    onChange={handleChange('num_int')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    id="outlined-name"
                                                    label="Numero externo"
                                                    className={classes2.textField}
                                                    value={values.num_ext}
                                                    onChange={handleChange('num_ext')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </div>
                                        </div>
                                    </form>}
                                    {activeStep === 3  && <form className={classes2.container} noValidate autoComplete="off">
                                        <div style={{width:"100%"}}>
                                            <h2 style={{textAlign: 'center', margin: '35px 0', color:'#00A19C'}}
                                                className="card-title font-weight-bolder">Para poder dar de alta al nuevo socio se tiene que pagar un KIT de bienvenida</h2>
                                        </div>
                                        <img style={{display:"block",maxWidth:"70%", margin:"0 auto"}} src={"/media/kit.jpeg"} />
                                        <div style={{width:"100%"}} />
                                        <div style={{width:"100%"}}>
                                            <h2 style={{textAlign: 'center', margin: '35px 0'}}
                                                className="card-title font-weight-bolder">Configuración de
                                                dirección entrega</h2>
                                            <p style={{textAlign: 'center'}}> Por favor selecciona la dirección a la
                                                cuál se te enviará el KIT.</p>
                                        </div>
                                        {
                                            currentListAddress &&
                                            (address[0] && address[0].city ? currentListAddress.slice(0, 1).concat(address) : currentListAddress.slice(0, 1)).map((item, ind) => {
                                                return (
                                                    <div
                                                        key={ind + "st1"}
                                                        style={{
                                                            margin: '25px 0',
                                                            padding: '25px 10px',
                                                            border: '1px',
                                                            borderStyle: 'dotted',
                                                            borderColor: 'black',
                                                            background: (currentAddress && currentAddress[1] === ind) ? '#C9F7F5' : ''
                                                        }}
                                                        onClick={() => {
                                                            setCurrentAddress([item, ind])
                                                        }}
                                                        className="col-xs-6 col-md-3 selected-address">
                                                                    <span style={{
                                                                        color: theme1.palette.secondary.main,
                                                                    }}
                                                                          className="mt-3 font-weight-bold font-size-sm">
                                                                        Dirección {ind + 1}
                                                                        <br/>
                                                                    </span>
                                                        {(item.street || values.street) + ' num.' + (item.num_ext || values.num_ext) + ' ' + (item.num_int || values.num_int)}
                                                        <br/>
                                                        {(item.colony || values.colony)}
                                                        <br/>
                                                        {(item.city || values.city)}
                                                        <br/>
                                                        {(item.state || values.state)}
                                                        <br/>
                                                    </div>
                                                )

                                            })
                                        }
                                        {
                                            currentListAddress &&
                                            (
                                                <div style={{
                                                    margin: '25px 0',
                                                    padding: '25px 10px',
                                                    border: '1px',
                                                    borderStyle: 'dotted',
                                                    borderColor: 'black',
                                                    background: (currentAddress && currentAddress[1] === currentListAddress.length) ? '#C9F7F5' : '',
                                                    width: '100%'
                                                }}
                                                     onClick={() => {
                                                         setCurrentAddress([{}, currentListAddress.length])
                                                         if(address[address.length - 1].postal_code !== ""){
                                                             setAddress([...address, {
                                                                 street:"",
                                                                 state:"",
                                                                 city:"",
                                                                 postal_code:"",
                                                                 colony:"",
                                                                 num_int:"",
                                                                 num_ext:"",
                                                                 address_id:"",
                                                             }])
                                                         }
                                                         showModal()
                                                     }}
                                                     className="col-xs-6 col-md-3 selected-address">
                                                    <h4 style={{textAlign: 'center', marginTop:'20px', color:'#00A19C'}}>
                                                        Agregar direción
                                                    </h4>
                                                </div>
                                            )
                                        }

                                        <div className="form-group row">

                                            <div className="col-5 offset-1">
                                                <h5 style={{
                                                    textAlign: 'left',
                                                    marginBottom: '20px',
                                                    marginTop: '20px'
                                                }}
                                                    className="font-weight-bolder">Fecha de entrega</h5>
                                                <DatePicker
                                                    disabledDate={disabledDate}
                                                    locale={locale2}
                                                    style={{width: '100%'}}
                                                    formar='MM-DD-YYYY hh:mm'
                                                    disabledTime={disabledDateTime}
                                                    onChange={(value) => {
                                                        let auxObj = {...currentDetails}
                                                        auxObj.date = value.format("DD MM YYYY");
                                                        setCurrentDetails(auxObj);
                                                    }}/>
                                            </div>
                                            <div className="col-5"
                                                 style={{marginBottom: '20px', marginTop: '20px'}}>
                                                <h5 style={{textAlign: 'left', margin: '5px 0'}}
                                                    className="font-weight-bolder">Forma de pago</h5>
                                                <Select onSelect={(value) => {
                                                    let auxObj = {...currentDetails};
                                                    auxObj.paymentMethod = value;
                                                    setCurrentDetails(auxObj);
                                                }} style={{width: '100%'}}
                                                        defaultValue={currentDetails.paymentMethod}
                                                >
                                                    <Option value={2}>
                                                        1 - Tarjeta de débito o credito
                                                    </Option>
                                                    <Option value={3}>
                                                        2 - Transferencia
                                                    </Option>
                                                </Select>
                                            </div>
                                            <div className="col-5 offset-1"
                                                 style={{marginBottom: '20px', marginTop: '20px'}}>
                                                <h5 style={{textAlign: 'left', margin: '5px 0'}}
                                                    className="font-weight-bolder">Nombre de quien recibe</h5>
                                                <InputAnt onChange={(e) => {
                                                    const { value } = e.target;
                                                    let auxObj = {...currentDetails};
                                                    auxObj.name = value;
                                                    setCurrentDetails(auxObj);
                                                }}
                                                          defaultValue={currentDetails.name}
                                                          style={{width: '100%'}}/>
                                            </div>
                                            <div className="col-5"
                                                 style={{marginBottom: '20px', marginTop: '20px'}}>
                                                <h5 style={{textAlign: 'left', margin: '5px 0'}}
                                                    className="font-weight-bolder">Número de contacto</h5>
                                                <InputAnt onChange={(e) => {
                                                    const { value } = e.target;
                                                    let auxObj = {...currentDetails}
                                                    auxObj.phoneNumber = value;
                                                    setCurrentDetails(auxObj);
                                                }}
                                                          defaultValue={currentDetails.phone}
                                                          style={{width: '100%'}}/>
                                            </div>
                                            <div className="col-10 offset-1">
                                                <h5 style={{
                                                    textAlign: 'left',
                                                    marginBottom: '20px',
                                                    marginTop: '20px'
                                                }}
                                                    className="font-weight-bolder">Indicaciones extra</h5>
                                                <TextArea
                                                    onChange={(e) => {
                                                        const { value } = e.target;
                                                        let auxObj = {...currentDetails}
                                                        auxObj.comments = value;
                                                        setCurrentDetails(auxObj);
                                                    }}
                                                    style={{width: '100%'}}/>
                                            </div>
                                        </div>
                                    </form>}
                                    {activeStep === 2  && <form className={classes2.container} noValidate autoComplete="off">
                                        <div className="row">
                                            <div className="col-xsl-12">
                                                <TextField
                                                    disabled
                                                    id="outlined-name"
                                                    label="Nombre(s)"
                                                    className={classes2.textField}
                                                    value={values.name}
                                                    onChange={handleChange('name')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    disabled
                                                    id="outlined-name"
                                                    label="Apellido paterno"
                                                    className={classes2.textField}
                                                    value={values.last_name_1}
                                                    onChange={handleChange('last_name_1')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    disabled
                                                    id="outlined-name"
                                                    label="Apellido materno"
                                                    className={classes2.textField}
                                                    value={values.last_name_2}
                                                    onChange={handleChange('last_name_2')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    disabled
                                                    id="outlined-name"
                                                    label="Email"
                                                    className={classes2.textField}
                                                    value={values.email}
                                                    onChange={handleChange('email')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    disabled
                                                    id="outlined-name"
                                                    label="Fecha de nacimiento"
                                                    className={classes2.textField}
                                                    value={values.dateOfBirth}
                                                    onChange={handleChange('dateOfBirth')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    disabled
                                                    id="outlined-name"
                                                    label="Calle"
                                                    className={classes2.textField}
                                                    value={values.street}
                                                    onChange={handleChange('street')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    disabled
                                                    id="outlined-name"
                                                    label="Estado"
                                                    className={classes2.textField}
                                                    value={values.state}
                                                    onChange={handleChange('state')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    disabled
                                                    id="outlined-name"
                                                    label="Municipio/alcaldia"
                                                    className={classes2.textField}
                                                    value={values.city}

                                                    onChange={handleChange('city')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    disabled
                                                    id="outlined-name"
                                                    label="Codigo Postal"
                                                    className={classes2.textField}
                                                    value={values.postal_code}
                                                    onChange={handleChange('postal_code')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    disabled
                                                    id="outlined-name"
                                                    label="Colonia"
                                                    className={classes2.textField}
                                                    value={values.colony}
                                                    onChange={handleChange('colony')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    disabled
                                                    id="outlined-name"
                                                    label="Numero interno"
                                                    className={classes2.textField}
                                                    value={values.num_int}
                                                    onChange={handleChange('num_int')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    disabled
                                                    id="outlined-name"
                                                    label="Numero externo"
                                                    className={classes2.textField}
                                                    value={values.num_ext}
                                                    onChange={handleChange('num_ext')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    disabled
                                                    id="outlined-name"
                                                    label="Número de telefono"
                                                    className={classes2.textField}
                                                    value={values.phone}
                                                    onChange={handleChange('phone')}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </div>
                                        </div>
                                    </form>}

                                    <div style={{float: currentDetails.paymentMethod !== 3  ? "right" : "left"}}>
                                        <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                        {currentError && <Typography
                                            className={classes.instructions} style={{color: 'red'}}>{currentError}</Typography>}
                                        <div>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                className={classes2.backButton}
                                            >
                                                Atras
                                            </Button>


                                            {currentDetails && currentDetails.paymentMethod !== 3  && (currentDetails.paymentMethod !== 2 || activeStep === 2) && <Button variant="contained" color="primary" onClick={handleNext}>
                                                {activeStep === steps.length - 1 ? 'Finalizar': 'Siguiente'}
                                            </Button>}
                                            {
                                                activeStep === steps.length - 1 && currentDetails && currentDetails.paymentMethod === 2 &&
                                                <PayPalScriptProvider options={{
                                                    components: "buttons",
                                                    "currency" : "MXN",
                                                    "locale": "es_MX",
                                                    "client-id": "AaWmCSKclojcM7hnaZe8HPjoAzB2qGgsgsnet1rE3HI0KrpZzw1WPb2mUVmF_3_YA5dJnog-YzF5LBRf" }}>
                                                    <PayPalButtons
                                                        createOrder={(data, actions) => {
                                                            setCurrentError(null);
                                                            return actions.order.create({
                                                                purchase_units: [
                                                                    {
                                                                        description: "Compra de productos Hotch",
                                                                        currency_code: "MXN",
                                                                        amount: {
                                                                            value: 850 ,
                                                                        },
                                                                    },
                                                                ],
                                                            });
                                                        }}
                                                        onApprove={(data, actions) => {
                                                            return actions.order.capture().then((details) => {
                                                                const name = details.payer.name.given_name;
                                                                saveUser();
                                                            });
                                                        }}
                                                        onError={()=>{
                                                            return setCurrentError(`Error al procesar la compra`);
                                                        }}
                                                        onCancel={()=>{
                                                            return setCurrentError('Proceso de pago cancelado');
                                                        }}
                                                        style={{ layout: "horizontal" }} />
                                                </PayPalScriptProvider>
                                            }
                                        </div>
                                    </div>
                                    {currentDetails && currentDetails.paymentMethod === 3 && activeStep === steps.length - 1 && (currentDetails.paymentMethod !== 2 || activeStep === 2) &&
                                        <div style={{float:'right'}}>
                                            <p style={{width: '320px', fontWeight:'bold'}}>
                                                Para finalizar la compra, debes de subir el comprobante del pago.
                                            </p>
                                            <Upload {...props}>
                                                <Button style={{height:'25px', float:'right', border:"1px solid black"}} icon={<UploadOutlined/>}>Selecionar Comprobante</Button>
                                            </Upload>
                                            <Button
                                                disabled={fileList.length === 0}
                                                loading={false}
                                                style={{marginTop: 16}}
                                                variant="contained" color="primary"
                                                onClick={handleNext}>
                                                Subir comprobante y completar pedido
                                            </Button>
                                        </div>
                                    }

                                </>
                            )}
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}
