// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, {useEffect, useMemo} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../_redux/products/productsActions";
import * as uiHelpers from "../ProductsUIHelpers";
import {
    getSelectRow,
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
} from "../../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import {Pagination} from "../../../../../../_metronic/_partials/controls";
import {useProductsUIContext} from "../ProductsUIContext";
import {Empty} from 'antd';

export function ProductsTable() {
    // Products UI Context
    const productsUIContext = useProductsUIContext();
    const dataAuth = useSelector(state => state.auth);

    const productsUIProps = useMemo(() => {
        return {
            ids: productsUIContext.ids,
            setIds: productsUIContext.setIds,
            queryParams: productsUIContext.queryParams,
            setQueryParams: productsUIContext.setQueryParams,
            openEditProductPage: productsUIContext.openEditProductPage,
            openDeleteProductDialog: productsUIContext.openDeleteProductDialog,
        };
    }, [productsUIContext]);

    // Getting curret state of products list from store (Redux)
    const {currentState} = useSelector(
        (state) => ({currentState: state.products}),
        shallowEqual
    );
    const {totalCount, entities, listLoading} = currentState;
    // Products Redux state
    const dispatch = useDispatch();
    useEffect(() => {
        // clear selections list
        productsUIProps.setIds([]);
        if (dataAuth.user.admin_user_id) {
            dispatch(actions.fetchProductsAdmin(productsUIProps.queryParams));
        } else {
            dispatch(actions.fetchProducts({type_user_id:dataAuth.user.type_user_id}));
        }
        // server call by queryParams
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productsUIProps.queryParams, dispatch]);
    // Table columns
    const columns = [
        {
            dataField: "sku",
            text: "SKU",
            sort: true,
            sortCaret: sortCaret,
        },
        {
            dataField: "name",
            text: "Nombre",
            sort: true,
            sortCaret: sortCaret,
        },
        {
            dataField: "model",
            text: "Model",
            sort: true,
            sortCaret: sortCaret,
        },
        {
            dataField: "brand",
            text: "Marca",
            sort: true,
            sortCaret: sortCaret,
        },
        {
            dataField: dataAuth.user.admin_user_id ? "internal_price" : "price",
            text: "Precio Int.",
            sort: true,
            sortCaret: sortCaret,
            formatter: columnFormatters.PriceColumnFormatter,
        },
        {
            dataField: "external_price",
            text: "Precio Ext.",
            sort: true,
            sortCaret: sortCaret,
            formatter: columnFormatters.PriceColumnFormatter,
        },
        {
            dataField: "stock",
            text: "Cantidad",
            sort: true,
            sortCaret: sortCaret,
        },
    ];

    // if(dataAuth.user.admin_user_id){
    //     columns.push({
    //         dataField: "action",
    //         text: "Acciones",
    //         formatter: columnFormatters.ActionsColumnFormatter,
    //         formatExtraData: {
    //             openEditProductPage: productsUIProps.openEditProductPage,
    //             openDeleteProductDialog: productsUIProps.openDeleteProductDialog,
    //         },
    //         classes: "text-right pr-0",
    //         headerClasses: "text-right pr-3",
    //         style: {
    //             minWidth: "100px",
    //         },
    //     })
    // }
    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: productsUIProps.queryParams.pageSize,
        page: productsUIProps.queryParams.pageNumber,
    };
    return (
        <>
            {entities && entities.length === 0 &&
            <Empty description="Sin datos disponibles por el momento o ocurrio un error en la conexión"/>}
            {entities && entities.length > 0 && <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({paginationProps, paginationTableProps}) => {
                    return (
                        // <Pagination
                        //     isLoading={listLoading}
                        //     paginationProps={paginationProps}
                        // >
                        <BootstrapTable
                            wrapperClasses="table-responsive"
                            classes="table table-head-custom table-vertical-center"
                            bootstrap4
                            bordered={false}
                            remote
                            keyField="id"
                            data={entities === null ? [] : entities}
                            columns={columns}
                            defaultSorted={uiHelpers.defaultSorted}
                            onTableChange={getHandlerTableChange(
                                productsUIProps.setQueryParams
                            )}
                            // selectRow={getSelectRow({
                            //     entities,
                            //     ids: productsUIProps.ids,
                            //     setIds: productsUIProps.setIds,
                            // })}
                            {...paginationTableProps}
                        >
                            <PleaseWaitMessage entities={entities}/>
                            <NoRecordsFoundMessage entities={entities}/>
                        </BootstrapTable>
                        // </Pagination>
                    );
                }}
            </PaginationProvider>}
        </>
    );
}
