// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {Input, Select} from "../../../../../../_metronic/_partials/controls";
import {
    AVAILABLE_BRAND,
    AVAILABLE_MANUFACTURES,
    ProductStatusTitles,
    ProductConditionTitles,
} from "../ProductsUIHelpers";

// Validation schema
const ProductEditSchema = Yup.object().shape({
    name: Yup.string().required("Requerido"),
    sku: Yup.string().required("Requerido"),
    brand: Yup.string().required("Requerido"),
    model: Yup.string().required("Requerido"),
    external_price: Yup.number().required("Requerido"),
    internal_price: Yup.number().required("Requerido"),
    stock: Yup.number().required("Requerido"),
    priceOne: Yup.number().required("Requerido"),
    priceTwo: Yup.number().required("Requerido"),
    priceThree: Yup.number().required("Requerido"),
    priceFour: Yup.number().required("Requerido"),
    priceFive: Yup.number().required("Requerido"),
    priceSix: Yup.number().required("Requerido"),
});

export function ProductEditForm({
                                    product,
                                    btnRef,
                                    saveProduct,
                                }) {
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={product}
                validationSchema={ProductEditSchema}
                onSubmit={(values) => {
                    console.log(values)
                    saveProduct(values);
                }}
            >
                {({handleSubmit}) => (
                    <>
                        <Form className="form form-label-right">
                            <div className="form-group row">
                                <div className="col-lg-4">
                                    <Field
                                        type="string"
                                        name="name"
                                        component={Input}
                                        placeholder="Nombre"
                                        label="Nombre"
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <Field
                                        type="string"
                                        name="sku"
                                        component={Input}
                                        placeholder="SKU"
                                        label="SKU"
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <Field
                                        name="model"
                                        component={Input}
                                        placeholder="Modelo"
                                        label="Modelo"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-4">
                                    <Field
                                        name="brand"
                                        component={Input}
                                        placeholder="Marca"
                                        label="Marca"
                                    />
                                    {/*<Select name="brand" label="Marca">*/}
                                    {/*    {AVAILABLE_BRAND.map((brand) => (*/}
                                    {/*        <option key={brand} value={brand}>*/}
                                    {/*            {brand}*/}
                                    {/*        </option>*/}
                                    {/*    ))}*/}
                                    {/*</Select>*/}
                                </div>
                                <div className="col-lg-4">
                                    <Field
                                        type="number"
                                        name="external_price"
                                        component={Input}
                                        placeholder="Precio externo"
                                        label="Precio Interno"
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <Field
                                        type="number"
                                        name="internal_price"
                                        component={Input}
                                        placeholder="Precio interno"
                                        label="Precio interno"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-4">
                                    <Field
                                        type="number"
                                        name="stock"
                                        component={Input}
                                        placeholder="Stock"
                                        label="Stock"
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <Field
                                        type="number"
                                        name="priceOne"
                                        component={Input}
                                        placeholder="Precio Interno Hotchiano"
                                        label="Precio Interno Hotchiano"
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <Field
                                        type="number"
                                        name="priceTwo"
                                        component={Input}
                                        placeholder="Precio distribuidores Personal"
                                        label="Precio distribuidores Personal"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-4">
                                    <Field
                                        type="number"
                                        name="priceThree"
                                        component={Input}
                                        placeholder="Precio distribuidores Empresarial"
                                        label="Precio distribuidores Empresarial"
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <Field
                                        type="number"
                                        name="priceFour"
                                        component={Input}
                                        placeholder="Precio Socios Hotchers"
                                        label="Precio Socios Hotchers"
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <Field
                                        type="number"
                                        name="priceFive"
                                        component={Input}
                                        placeholder="Precio Socios Hotchers (ORO)"
                                        label="Precio Socios Hotchers (ORO)"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-4">
                                    <Field
                                        type="number"
                                        name="priceSix"
                                        component={Input}
                                        placeholder="Precio Socios Hotchers (PLATINO)"
                                        label="Precio Socios Hotchers (PLATINO)"
                                    />
                                </div>
                            </div>
                            {/*<div className="form-group">*/}
                            {/*  <label>Description</label>*/}
                            {/*  <Field*/}
                            {/*    name="description"*/}
                            {/*    as="textarea"*/}
                            {/*    className="form-control"*/}
                            {/*  />*/}
                            {/*</div>*/}
                            <button
                                type="submit"
                                style={{display: "none"}}
                                ref={btnRef}
                                onSubmit={() => handleSubmit()}
                            ></button>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
