import React, {useEffect, useMemo} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { CustomersFilter } from "./customers-filter/CustomersFilter";
import { CustomersTable } from "./customers-table/CustomersTable";
import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";
import { useCustomersUIContext } from "./CustomersUIContext";
import * as actions from "../../_redux/orders/ordersActions";
import {useDispatch, useSelector} from "react-redux";

export function CustomersCard() {
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
    };
  }, [customersUIContext]);
  const dispatch = useDispatch();
  const getCSV = () =>{
    dispatch(actions.fetchOrdersAdminCSV(customersUIProps.queryParams));
  }
  const dataAuth = useSelector(state => state.auth);

  useEffect(()=>{

    // dataAuth.authToken;
    const updateForm = document.getElementById('updateForm')
    updateForm && updateForm.addEventListener('submit', (e) => {
      e.preventDefault()
      const formData = new FormData(updateForm)
      fetch('https://hbplat.serv-lab.com/api/private/orders/multiupdate', {
        method: 'POST',
        body: formData,
        headers: new Headers({
          'Authorization': 'Bearer '+ dataAuth.authToken,
        }),
      })
    })
  },[])


  return (
    <Card>
      <CardHeader title="Lista de Ordenes" >
        <CardHeaderToolbar>
          <button
              type="button"
              style={{marginRight:'15px'}}
              className="btn btn-primary"
              onClick={getCSV}
          >
            Descargar Ordenes CSV
          </button>
          {/*<form id="updateForm" style={{margin:'10px 0'}} method="post" action="https://hbplat.serv-lab.com/api/private/products/multiupdate">*/}
          {/*  <input type="file" id="myFile" name="batch" />*/}
          {/*  <input type="submit"  className="btn btn-primary" />*/}
          {/*</form>*/}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/*<CustomersFilter />*/}
        {/*{customersUIProps.ids.length > 0 && <CustomersGrouping />}*/}
        <CustomersTable />
      </CardBody>
    </Card>
  );
}
