import React, {useEffect, useState} from 'react';
import { AdvanceTablesWidget7, ListsWidget10, ListsWidget14 } from '../../../_metronic/_partials/widgets';
import {InviteProcess} from '../ECommerce/pages/users/InviteProcess';
import {useDispatch, useSelector} from 'react-redux';
import * as actionsUser from '../ECommerce/_redux/users/usersActions';
import {Team} from '../../components/dashboard/team/Team';
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../_metronic/_partials/controls";
import {CustomersTable} from "../ECommerce/pages/orders/customers-table/CustomersTable";
// import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Collapse } from 'antd';
import * as uiHelpers from "../ECommerce/pages/orders/CustomersUIHelpers";
import {
    getHandlerTableChange,
    headerSortingClasses,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret
} from "../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import * as columnFormatters from "../ECommerce/pages/orders/customers-table/column-formatters";
import moment from "moment/moment";
import {ProgressBar} from "../../components/dashboard/bonos/ProgressBar";
const { Panel } = Collapse;

export function MyTeam() {
    const dispatch = useDispatch();

    const dataUsers = useSelector(state => state.users);
    const dataAuth = useSelector(state => state.auth);
    const [customData, setCustomData] = useState([]);

    useEffect(() => {
        dispatch(actionsUser.fetchUserReport(dataAuth.user.user_id));
    },[])


    const sortStringAsc = (a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
    }

    const sortStringDesc = (a, b) => {
        if (a > b) return -1;
        if (a < b) return 1;
        return 0;
    }

    const onChange = (key,data) => {
        if(data){
            const order = data.sortOrder;
            const name = data.sortField;

            if(data.data){
                let auxCustomData = [...data.data];
                auxCustomData.sort((a,b) => {
                    if (order === 'asc'){
                        return sortStringAsc(a[name],b[name]);
                    } else{
                        return sortStringDesc(a[name],b[name]);
                    }
                });
                setCustomData(auxCustomData);

            }
            return [];
        }

    };

    console.log(customData);


    const columns = [
        {
            dataField: "username",
            text: "Usuario",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "total",
            text: "Facturación",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: (cellContent, row) =>{
                return cellContent.toLocaleString('es-MX', {
                    style: 'currency',
                    currency: 'MXN',
                });
            }
        },
        {
            dataField: "total",
            text: "Falta para objetivo",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: (cellContent, row) =>{
                cellContent = 1000 - cellContent;
                return cellContent > 0 ? cellContent.toLocaleString('es-MX', {
                    style: 'currency',
                    currency: 'MXN',
                }) : 'Completado';
            }
        }
    ];
    let percentage = 0;
    let totalTeam = 0;
    let totalTeamActiveTeam = 0;
    let totalActiveTeam = 0;
    if(dataUsers.report){
        percentage = (dataUsers.report.cyclesInformation[0].teamData.reduce((previousValue, currentValue)=>{
            return  previousValue + currentValue.total
        },0)/(dataUsers.report.cyclesInformation[0].teamData.length * 1000 * 0.70)) * 100;
        totalActiveTeam = parseInt((dataUsers.report.cyclesInformation[0].teamData.reduce((previousValue, currentValue)=>{
            return currentValue.total > 1000 ? previousValue + 1 : previousValue;
        },0)/(dataUsers.report.cyclesInformation[0].teamData.length)) * 100);
        totalTeamActiveTeam = parseInt((dataUsers.report.cyclesInformation[0].teamData.reduce((previousValue, currentValue)=>{
            return currentValue.total > 1000 ? previousValue + 1 : previousValue;
        },0)));
        totalTeam = dataUsers.report.cyclesInformation[0].teamData.length;
    }



    return (
        <Card>
            <CardHeader title="Mi equipo" >
                <CardHeaderToolbar>

                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <div className="row" >
                    <div className="col-12 col-lg-8">
                        <div className="card card-custom" style={{ marginTop: '15px'}}>
                            <div className="card-header info-title">
                                <h5>Ventas por ciclo</h5>
                            </div>
                        </div>
                        <div className="card-body">
                            {dataUsers.report && <Collapse defaultActiveKey={['1']} onChange={onChange}>
                                <div className="row" style={{position:"relative", left:'16px', marginTop:'20px', color:'#1E1E2D', fontWeight:'bold'}}>
                                    <div className="col-3">
                                        Fecha inicio: {moment(dataUsers.report.cyclesInformation[0].cycles_dates.split(' ')[0] , 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                    </div>
                                    <div className="col-3">
                                        Fecha fin: {moment(dataUsers.report.cyclesInformation[0].cycles_dates.split(' ')[1] , 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                    </div>
                                    <div className="col-3">
                                        Facturación: {dataUsers.report.cyclesInformation[0].total.toLocaleString('es-MX', {
                                        style: 'currency',
                                        currency: 'MXN',
                                    })}

                                    </div>
                                </div>
                                <Panel header={'(Actual)' + dataUsers.report.cyclesInformation[0].cycle + ' ' } key="1">
                                    <BootstrapTable
                                        wrapperClasses="table-responsive"
                                        bordered={false}
                                        classes="table table-head-custom table-vertical-center"
                                        bootstrap4
                                        remote
                                        keyField="id"
                                        data={dataUsers.report.cyclesInformation[0].teamData === null ? [] : customData && customData.length > 0 ? customData : dataUsers.report.cyclesInformation[0].teamData}
                                        columns={columns}
                                        onTableChange={onChange}
                                    >
                                        <PleaseWaitMessage entities={dataUsers.report.cyclesInformation[0].teamData}/>
                                        <NoRecordsFoundMessage entities={dataUsers.report.cyclesInformation[0].teamData}/>
                                    </BootstrapTable>
                                </Panel>
                                <div className="row" style={{position:"relative", left:'16px', marginTop:'20px', color:'#1E1E2D', fontWeight:'bold'}}>
                                    <div className="col-3">
                                        Fecha inicio: {moment(dataUsers.report.cyclesInformation[1].cycles_dates.split(' ')[0] , 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                    </div>
                                    <div className="col-3">
                                        Fecha fin: {moment(dataUsers.report.cyclesInformation[1].cycles_dates.split(' ')[1] , 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                    </div>
                                    <div className="col-3">
                                        Facturación: {dataUsers.report.cyclesInformation[1].total.toLocaleString('es-MX', {
                                        style: 'currency',
                                        currency: 'MXN',
                                    })}

                                    </div>
                                </div>
                                <Panel header={dataUsers.report.cyclesInformation[1].cycle + ' ' + dataUsers.report.cyclesInformation[1].total.toLocaleString('es-MX', {
                                    style: 'currency',
                                    currency: 'MXN',
                                })} key="2">
                                    <BootstrapTable
                                        wrapperClasses="table-responsive"
                                        bordered={false}
                                        classes="table table-head-custom table-vertical-center"
                                        bootstrap4
                                        remote
                                        keyField="id"
                                        data={dataUsers.report.cyclesInformation[1].teamData === null ? [] : dataUsers.report.cyclesInformation[1].teamData}
                                        columns={columns}
                                        defaultSorted={uiHelpers.defaultSorted}
                                    >
                                        <PleaseWaitMessage entities={dataUsers.report.cyclesInformation[1].teamData}/>
                                        <NoRecordsFoundMessage entities={dataUsers.report.cyclesInformation[1].teamData}/>
                                    </BootstrapTable>
                                </Panel>
                                <div className="row" style={{position:"relative", left:'16px', marginTop:'20px', color:'#1E1E2D', fontWeight:'bold'}}>
                                    <div className="col-3">
                                        Fecha inicio: {moment(dataUsers.report.cyclesInformation[2].cycles_dates.split(' ')[0] , 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                    </div>
                                    <div className="col-3">
                                        Fecha fin: {moment(dataUsers.report.cyclesInformation[2].cycles_dates.split(' ')[1] , 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                    </div>
                                    <div className="col-3">
                                        Facturación: {dataUsers.report.cyclesInformation[2].total.toLocaleString('es-MX', {
                                        style: 'currency',
                                        currency: 'MXN',
                                    })}

                                    </div>
                                </div>
                                <Panel header={dataUsers.report.cyclesInformation[2].cycle + ' ' + dataUsers.report.cyclesInformation[2].total.toLocaleString('es-MX', {
                                    style: 'currency',
                                    currency: 'MXN',
                                })} key="3">
                                    <BootstrapTable
                                        wrapperClasses="table-responsive"
                                        bordered={false}
                                        classes="table table-head-custom table-vertical-center"
                                        bootstrap4
                                        remote
                                        keyField="id"
                                        data={dataUsers.report.cyclesInformation[2].teamData === null ? [] : dataUsers.report.cyclesInformation[2].teamData}
                                        columns={columns}
                                        defaultSorted={uiHelpers.defaultSorted}
                                    >
                                        <PleaseWaitMessage entities={dataUsers.report.cyclesInformation[2].teamData}/>
                                        <NoRecordsFoundMessage entities={dataUsers.report.cyclesInformation[2].teamData}/>
                                    </BootstrapTable>
                                </Panel>
                                <div className="row" style={{position:"relative", left:'16px', marginTop:'20px', color:'#1E1E2D', fontWeight:'bold'}}>
                                    <div className="col-3">
                                        Fecha inicio: {moment(dataUsers.report.cyclesInformation[3].cycles_dates.split(' ')[0] , 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                    </div>
                                    <div className="col-3">
                                        Fecha fin: {moment(dataUsers.report.cyclesInformation[3].cycles_dates.split(' ')[1] , 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                    </div>
                                    <div className="col-3">
                                        Facturación: {dataUsers.report.cyclesInformation[3].total.toLocaleString('es-MX', {
                                        style: 'currency',
                                        currency: 'MXN',
                                    })}

                                    </div>
                                </div>
                                <Panel header={dataUsers.report.cyclesInformation[3].cycle + ' ' + dataUsers.report.cyclesInformation[3].total.toLocaleString('es-MX', {
                                    style: 'currency',
                                    currency: 'MXN',
                                })} key="4">
                                    <BootstrapTable
                                        wrapperClasses="table-responsive"
                                        bordered={false}
                                        classes="table table-head-custom table-vertical-center"
                                        bootstrap4
                                        remote
                                        keyField="id"
                                        data={dataUsers.report.cyclesInformation[3].teamData === null ? [] : dataUsers.report.cyclesInformation[3].teamData}
                                        columns={columns}
                                        defaultSorted={uiHelpers.defaultSorted}
                                    >
                                        <PleaseWaitMessage entities={dataUsers.report.cyclesInformation[3].teamData}/>
                                        <NoRecordsFoundMessage entities={dataUsers.report.cyclesInformation[3].teamData}/>
                                    </BootstrapTable>
                                </Panel>
                            </Collapse>}
                        </div>
                    </div>

                    <div className="col-12 col-lg-4">
                        {dataUsers.report && <div className="card card-custom" style={{ marginTop: '15px'}}>
                            <div className="card-header info-title">
                                <h5>Ciclo actual información</h5>
                            </div>
                            <div className="card-body">
                                <div className="bar-component">
                                    <ProgressBar
                                        name={'Objetivo de venta por equipo en ciclo - Meta:' + (dataUsers.report.cyclesInformation[0].teamData.length * 1000  * 0.70).toLocaleString('es-MX', {
                                            style: 'currency',
                                            currency: 'MXN',
                                        })}
                                        total={dataUsers.report.cyclesInformation[0].teamData.length * 1000}
                                        totalPercentage={percentage}
                                        desc={(percentage >=  100 ? "Felicidades, lograron su objetivo" : "Actual:" + dataUsers.report.cyclesInformation[0].teamData.reduce((previousValue, currentValue)=>{
                                            return previousValue + currentValue.total
                                        },0).toLocaleString('es-MX', {
                                            style: 'currency',
                                            currency: 'MXN',
                                        }))}
                                        totalBonus={500}/>
                                    <h6> Porcentaje de miembros activos: {totalActiveTeam} % </h6>
                                    <p style={{marginBottom:0}}>Número de miembros activos:  {totalTeamActiveTeam}</p>
                                    <p>Número de miembros:  {totalTeam}</p>


                                </div>
                            </div>
                        </div>}
                        <div className="card card-custom" style={{ marginTop: '15px'}}>
                            <div className="card-header info-title">
                                <h5>Equipo</h5>
                            </div>
                            <div className="card-body">
                                { dataUsers.report && dataUsers.report.teamData &&
                                    <div className="order-1 order-xxl-2">
                                        <ListsWidget14 data={dataUsers.report && dataUsers.report.teamData} className="card-stretch gutter-b"/>
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </CardBody>
        </Card>

  );
}
