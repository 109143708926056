import React, {useEffect} from "react";
import {
    MixedWidget14,
    ListsWidget14,
    AdvanceTablesWidget2,
} from "../widgets";
import  { Carousel } from '../../../app/components/dashboard/bonos/Carousel';
import  { Bonos } from '../../../app/components/dashboard/bonos/Bonos';
import * as actions from "../../../app/modules/ECommerce/_redux/customers/customersActions";
import * as actionsO from "../../../app/modules/ECommerce/_redux/orders/ordersActions";
import {useDispatch, useSelector} from "react-redux";
import * as actionsUser from "../../../app/modules/ECommerce/_redux/users/usersActions";
import {ProgressBar} from "../../../app/components/dashboard/bonos/ProgressBar";
import {ProgressBarDiv} from "../../../app/components/dashboard/bonos/ProgressBarDivided";

export function Demo1Dashboard() {
    const dispatch = useDispatch();
    const dataAuth = useSelector(state => state.auth);
    const dataUsers = useSelector(state => state.users);

    let percentage = 0;
    let totalTeam = 0;
    let totalTeamActiveTeam = 0;
    let totalActiveTeam = 0;
    if(dataUsers.report){
        percentage = (dataUsers.report.cyclesInformation[0].teamData.reduce((previousValue, currentValue)=>{
            return  previousValue + currentValue.total
        },0)/(dataUsers.report.cyclesInformation[0].teamData.length * 1000 * 0.70)) * 100;
        totalActiveTeam = parseInt((dataUsers.report.cyclesInformation[0].teamData.reduce((previousValue, currentValue)=>{
            return currentValue.total > 1000 ? previousValue + 1 : previousValue;
        },0)/(dataUsers.report.cyclesInformation[0].teamData.length)) * 100);
        totalTeamActiveTeam = parseInt((dataUsers.report.cyclesInformation[0].teamData.reduce((previousValue, currentValue)=>{
            return currentValue.total > 1000 ? previousValue + 1 : previousValue;
        },0)));
        totalTeam = dataUsers.report.cyclesInformation[0].teamData.length;
    }
    useEffect(() => {
        // clear selections list// server call by queryParams
        if(dataAuth.user.admin_user_id){
            dispatch(actions.fetchCustomersAdmin());
            dispatch(actionsO.fetchOrdersAdmin());
        }else{
            dispatch(actions.fetchCustomers({user_id:dataAuth.user.user_id}));
            dispatch(actionsO.fetchOrders({user_id:dataAuth.user.user_id}));
            dispatch(actionsUser.fetchUserReport(dataAuth.user.user_id));

        }    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<>
        <div className="row">
            <div className="col-md-4 col-12 order-1">
                <img style={{maxWidth:'100%'}} src="/media/premio.jpeg" />
                {/*<AdvanceTablesWidget2 className="card-stretch gutter-b"/>*/}
            </div>
            <div className="col-md-4 col-12 order-1">
                <Bonos />
                {/*<AdvanceTablesWidget2 className="card-stretch gutter-b"/>*/}
            </div>
            <div className="col-md-4 col-12 order-1">
                { dataUsers.report && dataUsers.report.teamData &&
                        <div>
                            <div className="card-header bono-title">
                                <h5> Progreso equipo </h5>
                            </div>
                            <div className="card-body">
                                <div className="bar-component">
                                    <ProgressBarDiv
                                        name={"Objetivo de liderazgo"}
                                        total={0}
                                        totalprogress={0}
                                        relativePercentageB1={0}
                                        relativePercentageB2={0}
                                        relativePercentageB3={0}
                                        relativePercentageB4={0}
                                        desc="Cada miembro debe vender como mínimo de 4000 al mes"
                                        totalBonus={0}/>
                                </div>
                                <div style={{marginTop:'35px'}} className="bar-component">
                                    <ProgressBar
                                        name={'Objetivo de venta por equipo en ciclo - Meta:' + (dataUsers.report.cyclesInformation[0].teamData.length * 1000  * 0.70).toLocaleString('es-MX', {
                                            style: 'currency',
                                            currency: 'MXN',
                                        })}
                                        total={dataUsers.report.cyclesInformation[0].teamData.length * 1000}
                                        totalPercentage={percentage}
                                        desc={(percentage >=  100 ? "Felicidades, lograron su objetivo" : "Actual:" + dataUsers.report.cyclesInformation[0].teamData.reduce((previousValue, currentValue)=>{
                                            return previousValue + currentValue.total
                                        },0).toLocaleString('es-MX', {
                                            style: 'currency',
                                            currency: 'MXN',
                                        }))}
                                        totalBonus={500}/>
                                    <h6> Porcentaje de miembros activos: {totalActiveTeam} % </h6>
                                    <p style={{marginBottom:0}}>Número de miembros activos:  {totalTeamActiveTeam}</p>
                                    <p>Número de miembros:  {totalTeam}</p>

                                </div>


                            </div>
                        </div>
                }
            </div>
        </div>
        <div className="row">
            <div className="col-lg-6 col-xxl-3 order-1 order-xxl-2">
                {/*<MixedWidget14 className="card-stretch gutter-b" />*/}
            </div>



        </div>

    </>);
}
