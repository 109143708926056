// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import {Modal} from "react-bootstrap";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {
    Input,
    Select,
    DatePickerField,
} from "../../../../../../_metronic/_partials/controls";
import {useSelector} from "react-redux";
import { DatePicker } from 'antd';

// Validation schema
const CustomerEditSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Firstname is required"),
    last_name_1: Yup.string()
        .min(2, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Lastname is required"),
    last_name_2: Yup.string()
        .min(2, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Lastname is required"),
    email: Yup.string()
        .email("Invalid email")
        .required("Email is required"),
    dateOfBirth: Yup.mixed()
        .nullable(true),
    street: Yup.mixed()
        .nullable(true)
        .required("Requerido"),
    state: Yup.mixed()
        .nullable(true)
        .required("Requerido"),
    city: Yup.mixed()
        .nullable(false)
        .required("Requerido"),
    colony: Yup.mixed()
        .nullable(false)
        .required("Requerido"),
    postal_code: Yup.mixed()
        .nullable(false)
        .required("Requerido"),
    num_int: Yup.mixed()
        .nullable(false)
        .required("Requerido"),
    num_ext: Yup.mixed()
        .nullable(false),
    phone: Yup.mixed()
        .nullable(false)
        .required("Requerido"),
    genre: Yup.mixed()
        .nullable(false)
});

export function CustomerEditForm({
                                     saveCustomer,
                                     customer,
                                     actionsLoading,
                                     onHide,
                                 }) {
    const dataAuth = useSelector(state => state.auth);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={customer}
                validationSchema={CustomerEditSchema}
                onSubmit={(values) => {
                    if(customer.preview){
                        let auxObj = {...values};
                        let auxDate = null
                        if(auxObj.dateOfBirth.length > 0){
                            console.log(auxObj.dateOfBirth.length)
                            auxDate = auxObj.dateOfBirth.split('T')[0];
                        }else{
                            console.log( auxObj.dateOfBirth)
                            auxDate = customer.birthday
                        }
                        let customerToSend = {
                            client_id:auxObj.id,
                            name:auxObj.name,
                            last_name_1:auxObj.last_name_1,
                            last_name_2:auxObj.last_name_2,
                            email:auxObj.email,
                            genre:auxObj.genre,
                            birthday:auxDate,
                            street:auxObj.street,
                            state:auxObj.state,
                            city:auxObj.city,
                            postal_code:auxObj.postal_code,
                            colony:auxObj.colony,
                            num_int:auxObj.num_int,
                            phone:auxObj.phone,
                            num_ext:auxObj.num_ext,
                            address_id:customer.address_id,
                            user_id:customer.user_id
                        };
                        console.log("customerToSend")
                        saveCustomer(customerToSend);
                    }else{
                        if(dataAuth.user.user_id){
                            let auxObj = {...values};
                            auxObj.user_id = dataAuth.user.user_id;
                            saveCustomer(auxObj);
                        }else{
                            saveCustomer(values);
                        }
                    }
                }}
            >
                {({handleSubmit}) => (
                    <>
                        {/*<Modal.Body className="overlay overlay-block">*/}
                        <Modal.Body >
                            {/*{actionsLoading && (*/}
                            {/*    <div className="overlay-layer bg-transparent">*/}
                            {/*        <div className="spinner spinner-lg spinner-success"/>*/}
                            {/*    </div>*/}
                            {/*)}*/}

                            <Form className="form form-label-right">
                                <div className="form-group row">
                                    {/* First Name */}
                                    <div className="col-lg-4">
                                        <Field
                                            name="name"
                                            component={Input}
                                            placeholder="Nombre(s)"
                                            label="Nombre(s)"
                                        />
                                    </div>
                                    {/* Last Name */}
                                    <div className="col-lg-4">
                                        <Field
                                            name="last_name_1"
                                            component={Input}
                                            placeholder="Apellido paterno"
                                            label="Apellido paterno"
                                        />
                                    </div>
                                    {/* Last Name */}
                                    <div className="col-lg-4">
                                        <Field
                                            name="last_name_2"
                                            component={Input}
                                            placeholder="Apellido materno"
                                            label="Apellido materno"
                                        />
                                    </div>
                                </div>
                                {/* Email */}
                                <div className="form-group row">
                                    <div className="col-lg-4">
                                        <Field
                                            type="email"
                                            name="email"
                                            component={Input}
                                            placeholder="Email"
                                            label="Email"
                                        />
                                    </div>
                                    {/* Date of birth */}
                                    <div className="col-lg-4">
                                        {/*<DatePicker onChange={onChange} />*/}

                                        <DatePickerField
                                            name="dateOfBirth"
                                            label="Fecha de nacimiento"
                                        />
                                    </div>
                                    {/* IP Address */}
                                    <div className="col-lg-4">
                                        <Field
                                            name="street"
                                            component={Input}
                                            placeholder="Calle"
                                            label="Calle"
                                            customFeedbackLabel="La información de todos los usuarios es privada."
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    {/* Last Name */}
                                    <div className="col-lg-4">
                                        <Field
                                            name="state"
                                            component={Input}
                                            placeholder="Estado"
                                            label="Estado"
                                        />
                                    </div>
                                    {/* First Name */}
                                    <div className="col-lg-4">
                                        <Field
                                            name="city"
                                            component={Input}
                                            placeholder="Municipio/alcaldia"
                                            label="Municipio/alcaldia"
                                        />
                                    </div>
                                    {/* Last Name */}
                                    <div className="col-lg-4">
                                        <Field
                                            name="postal_code"
                                            component={Input}
                                            placeholder="Codigo Postal"
                                            label="Codigo Postal"
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    {/* First Name */}
                                    <div className="col-lg-4">
                                        <Field
                                            name="colony"
                                            component={Input}
                                            placeholder="Colonia"
                                            label="Colonia"
                                        />
                                    </div>
                                    {/* Last Name */}
                                    <div className="col-lg-4">
                                        <Field
                                            name="num_int"
                                            component={Input}
                                            placeholder="Numero interno"
                                            label="Numero interno"
                                        />
                                    </div>
                                    {/* Last Name */}
                                    <div className="col-lg-4">
                                        <Field
                                            name="num_ext"
                                            component={Input}
                                            placeholder="Numero externo"
                                            label="Numero externo"
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    {/* Gender */}
                                    <div className="col-lg-4">
                                        <Select defaultValue={customer && customer.preview ? customer.genre: 'Mujer'} name="genre" label="Seleccionar genero">
                                            <option value="Mujer">Mujer</option>
                                            <option value="Hombre">Hombre</option>
                                        </Select>
                                    </div>
                                    <div className="col-lg-4">
                                        <Field
                                            name="phone"
                                            component={Input}
                                            placeholder="Número de telefono"
                                            label="Número de telefono"
                                        />
                                    </div>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                onClick={onHide}
                                className="btn btn-light btn-elevate"
                            >
                                Cancelar
                            </button>
                            <> </>
                            <button
                                type="submit"
                                onClick={() => {
                                  handleSubmit();
                                }}
                                className="btn btn-primary btn-elevate"
                            >
                                Guardar
                            </button>
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </>
    );
}
