/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useSelector} from 'react-redux';
import {toAbsoluteUrl} from '../../../../_metronic/_helpers';
import SVG from 'react-inlinesvg';

import {ProgressBar} from './ProgressBar'
import {ProgressBarDiv} from './ProgressBarDivided'
import {Bottle} from './Bottle'
import {Bottle2} from './Bottle2'

import './Carousel.css'
import './ProgressBar.scss'

export function Bonos({total}) {

  const userAuth = useSelector(state => state.auth);
  console.log('SELECTOR AUTH');
  console.log(userAuth);
  const userType = userAuth.user.type_user_id;

  const dataUsers = useSelector(state => state.users);
  const userReport = dataUsers.report;
  let currentMonthTotal = 0;
  let currentMonthTotal11 = 10000;
  let team = 0;

  if(userReport != null){

    team = userReport.teamData;
    currentMonthTotal = userReport.currentMonthTotal;
    console.log("total de ventas del mes: " + currentMonthTotal);
    console.log(team);
  }

  console.log(total);
  // currentMonthTotal = 7875.54;
  /*
  Bono 1: debe de dividirse entre 5000 y el resultado de eso se multiplica por 500 y ese es el total del bono,
  estrictamente numeros enteros. Pero para hacer el porcentaje, se hará con respecto al bono actual,
  es decir, se hará %5000 y el sobrante de la división será entre 0 y 5000, y con ese valor llenaremos la botella. */
  const totalProgressB1 = (currentMonthTotal % 5000);
  // const totalPercentageB1 = (totalProgressB1 / 50).toFixed(1);
  const totalPercentageB1 = 69;
  const totalBonusB1 = (Math.floor(currentMonthTotal / 5000) * 500);
  const relativePercentageB1 = totalPercentageB1 == 100? 25 : (25 * totalPercentageB1) / 100;

  /*
  Bono 2: al llegar a 16000 en ventas, se accederá a una nueva lista de precios con descuentos, solo se muestra el avance
  */
  const totalProgressB2 = (currentMonthTotal % 16000);
  // const totalPercentageB2 = currentMonthTotal <= 16000? (currentMonthTotal / 160).toFixed(1) : 100;
  const totalPercentageB2 = 100;
  const relativePercentageB2 = totalPercentageB2 == 100? 25 : (25 * totalPercentageB2) / 100;
  /*
  Bono 3: Por cada 12k en venta el hotcher recibe un premio fisico
  */
  const totalProgressB3 = (currentMonthTotal % 12000);
  // const totalPercentageB3 = currentMonthTotal <= 12000? (currentMonthTotal / 120).toFixed(1) : 100;
  const totalPercentageB3 = 87;
  const relativePercentageB3 = totalPercentageB3 == 100? 25 : (25 * totalPercentageB3) / 100;
  /*
  Bono 4: Por cada 1700 de venta el hotcher recibe premio fisico
  */
  const totalProgressB4 = (currentMonthTotal % 1700);
  // const totalPercentageB4 = (totalProgressB4 / 17).toFixed(1);
  const totalPercentageB4 = 18;
  const totalBonusB4 = Math.floor(currentMonthTotal / 1700);
  const relativePercentageB4 = totalPercentageB4 == 100? 25 : (25 * totalPercentageB4) / 100;


  if(userType === 1){
    return (
      <div className="card card-custom">

        <div className="card-header bono-title">
          <h5> Progreso propio</h5>
        </div>

        <div className="card-body">
          <div className="bar-component">
            <ProgressBar
              name={'Premio de ciclo'}
              total={currentMonthTotal}
              totalprogress={totalProgressB1}
              totalPercentage={totalPercentageB1}
              desc="$1200.00 pesos para alcanzar el premio"
              totalBonus={totalBonusB1}/>
              <p style={{fontWeight:"bold",marginBottom:'25px'}}>Faltan $800.00 MXN para alcanzar la meta </p>
          </div>
          <div className="bar-component">
            <ProgressBar
              name={'Actividad mensual'}
              total={currentMonthTotal}
              totalprogress={totalProgressB1}
              totalPercentage={totalPercentageB2}
              desc="$1,000.00 pesos para ser considerado como activo"
              totalBonus={totalBonusB1}/>
            <p style={{fontWeight:"bold",marginBottom:'25px'}}>Faltan $200.00 MXN para alcanzar la meta </p>

          </div>
          <div className="bar-component">
            <ProgressBar
              name={'Meta crecimiento de venta actual'}
              total={currentMonthTotal}
              totalprogress={totalProgressB1}
              totalPercentage={totalPercentageB3}
              desc="Premio físico por cada $12,000.00 de venta"
              totalBonus={totalBonusB1}/>
            <p style={{fontWeight:"bold",marginBottom:'25px'}}>Faltan $800.00 MXN para alcanzar la meta </p>

          </div>
        </div>
      </div>


    );
  } else{
    return (
      <div className="card card-custom">
        <div className="card-header bono-title">
          <p> Titulo </p>
        </div>
        <div className="card-body">
          <div className="bar-component">
            <ProgressBarDiv
              name={"Barra 1"}
              total={currentMonthTotal}
              totalprogress={totalProgressB1}
              relativePercentageB1={relativePercentageB1}
              relativePercentageB2={relativePercentageB2}
              relativePercentageB3={relativePercentageB3}
              relativePercentageB4={relativePercentageB4}
              totalBonus={totalBonusB1}/>
          </div>
          <div className="bar-component">
            <ProgressBarDiv
              name={"Barra 2"}
              total={currentMonthTotal}
              totalprogress={totalProgressB1}
              relativePercentageB1={relativePercentageB1}
              relativePercentageB2={relativePercentageB2}
              relativePercentageB3={relativePercentageB3}
              relativePercentageB4={relativePercentageB4}
              totalBonus={totalBonusB1}/>
          </div>
          <div className="bar-component">
            <ProgressBarDiv
              name={"Barra 3"}
              total={currentMonthTotal}
              totalprogress={totalProgressB1}
              relativePercentageB1={relativePercentageB1}
              relativePercentageB2={relativePercentageB2}
              relativePercentageB3={relativePercentageB3}
              relativePercentageB4={relativePercentageB4}
              totalBonus={totalBonusB1}/>
          </div>
          <div className="bar-component">
            <ProgressBarDiv
              name={"Barra 4"}
              total={currentMonthTotal}
              totalprogress={totalProgressB1}
              relativePercentageB1={relativePercentageB1}
              relativePercentageB2={relativePercentageB2}
              relativePercentageB3={relativePercentageB3}
              relativePercentageB4={relativePercentageB4}
              totalBonus={totalBonusB1}/>
          </div>

        </div>
      </div>
    );
  }
}
