import * as requestFromServer from "./ordersCrud";
import {ordersSlice, callTypes} from "./ordersSlice";

const {actions} = ordersSlice;

export const fetchOrders = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findOrders(queryParams)
    .then(response => {
        const totalCount = response.data.orders.length;
        const entities = response.data.orders;
        dispatch(actions.ordersFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find orders";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchOrdersSaved = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findOrdersSaved(queryParams)
    .then(response => {
        const totalCount = response.data.orders.length;
        const entities = response.data.orders;
        dispatch(actions.ordersFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find orders";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const fetchOrdersAdmin = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findOrdersAdmin(queryParams)
    .then(response => {
      const totalCount = response.data.orders.length;
      const entities = response.data.orders;
      dispatch(actions.ordersFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find orders";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchOrdersAdminSaved = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findOrdersAdminSaved(queryParams)
    .then(response => {
      const totalCount = response.data.orders.length;
      const entities = response.data.orders;
      dispatch(actions.ordersFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find orders";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchOrder = id => dispatch => {
  if (!id) {
    return dispatch(actions.orderFetched({ orderForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getOrderById(id)
    .then(response => {
      const order = response.data;
      dispatch(actions.orderFetched({ orderForEdit: order }));
    })
    .catch(error => {
      error.clientMessage = "Can't find order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteOrder = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteOrder(id)
    .then(response => {
      dispatch(actions.orderDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createOrder = orderForCreation => dispatch => {
  console.log(orderForCreation)
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createOrder(orderForCreation)
    .then(response => {
      const { order } = response.data;
      dispatch(actions.orderCreated({ order }));
    })
    .catch(error => {
      error.clientMessage = "Can't create order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateOrder = order => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateOrder(order)
    .then(() => {
      dispatch(actions.orderUpdated({ order }));
    })
    .catch(error => {
      error.clientMessage = "Can't update order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchOrdersAdminCSV = queryParams => dispatch => {
    return requestFromServer
        .getAllOrdersCSV(queryParams)
        .then(response => {
            const type = response.headers['content-type']
            const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'orders.csv'
            link.click()
        })
        .catch(error => {
            error.clientMessage = "Can't find customers";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const fetchOrdersAdminCSVById = queryParams => dispatch => {
    return requestFromServer
        .getAllOrdersCSVById(queryParams)
        .then(response => {
            const type = response.headers['content-type']
            const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'orders.csv'
            link.click()
        })
        .catch(error => {
            error.clientMessage = "Can't find customers";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};


export const fetchReceipt = queryParams => dispatch => {
    return requestFromServer
        .getReceipt(queryParams)
        .then(response => {
            const type = response.headers['content-type'];
            const blob = new Blob([response.data],  { type })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Certificado-' + queryParams + '.' + type.split('/')[1]
            link.click()
        })
        .catch(error => {
            console.log(error)
            error.clientMessage = "Can't find customers";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const updateOrdersStatus = (status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForOrders(status)
    .then(() => {
      dispatch(actions.ordersStatusUpdated({ status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update orders status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteOrders = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteOrders(ids)
    .then(() => {
      dispatch(actions.ordersDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete orders";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
