import axios from "axios";

export const CUSTOMERS_URL = "https://hbplat.serv-lab.com/api/private/users";

// CREATE =>  POST: add a new user to the server
export function createUser(user) {
  return axios.post(CUSTOMERS_URL + '/create', { user });
}


// CREATE =>  POST: add a new user to the server
export function createAddress(address) {
  return axios.post(CUSTOMERS_URL + '/address/create',  address );
}

// CREATE =>  POST: add a new user to the server
export function getUserByIdReport(id) {
  return axios.post(CUSTOMERS_URL + '/report',  { id } );
}

// READ
export function getAllUsers() {
  return axios.get(`${CUSTOMERS_URL}/getAll`);
}

// READ
export function getAllAddress(queryParams) {
  return axios.post(`${CUSTOMERS_URL}/getAllAddress`,{...queryParams});
}

export function getUserById(userId) {
  return axios.get(`${CUSTOMERS_URL}/${userId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findUsers(queryParams) {
  return axios.post(`${CUSTOMERS_URL}/find`, { queryParams });
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findUsersAdmin(queryParams) {
  return axios.get(`${CUSTOMERS_URL}/getAll`, { queryParams });
}

// UPDATE => PUT: update the user on the server
export function updateUser(user) {
  return axios.post(`${CUSTOMERS_URL}/update/${user.user_id}`, { user });
}


export function unblockUser(id) {
  return axios.get(`${CUSTOMERS_URL}/unblock/${id}`);
}

// UPDATE Status
export function updateStatusForUsers(ids, status) {
  return axios.post(`${CUSTOMERS_URL}/updateStatusForUsers`, {
    ids,
    status
  });
}

// DELETE => delete the user from the server
export function deleteUser(userId) {
  return axios.delete(`${CUSTOMERS_URL}/${userId}`);
}

// DELETE Users by ids
export function deleteUsers(ids) {
  return axios.post(`${CUSTOMERS_URL}/deleteUsers`, { ids });
}
