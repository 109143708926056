import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/customers/customersActions";
import { CustomerEditDialogHeader } from "./CustomerEditDialogHeader";
import { CustomerEditForm } from "./CustomerEditForm";
import { useCustomersUIContext } from "../CustomersUIContext";

export function CustomerEditDialog({ id, show, onHide }) {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      initCustomer: customersUIContext.initCustomer,
    };
  }, [customersUIContext]);

  // Customers Redux state
  const dispatch = useDispatch();
  const { actionsLoading, customerForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.customers.actionsLoading,
      customerForEdit: state.customers.customerForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Customer by id
    dispatch(actions.fetchCustomer(id));
  }, [id, dispatch]);

  // server request for saving customer
  const saveCustomer = (customer) => {
    if (!id) {
      // server request for creating customer
      dispatch(actions.createCustomer(customer)).then(() => onHide());
    } else {
      console.log("update")
      // server request for updating customer
      dispatch(actions.updateCustomer(customer)).then(() => onHide());
    }
  };
  let previewCustomer = null;
  if(customerForEdit){
    let aux = {...customerForEdit}
    console.log(aux)
    previewCustomer = {
      id: aux.client_id,
      preview:true,
      name: aux.name,
      last_name_1:aux.last_name_1,
      last_name_2:aux.last_name_2,
      email:aux.email,
      genre:aux.genre,
      phone:aux.phone,
      dateOfBirth:"",
      birthday:aux.birthday,
      street:aux.Address.street,
      state:aux.Address.state,
      city:aux.Address.city,
      postal_code:aux.Address.postal_code,
      colony:aux.Address.colony,
      num_int:aux.Address.num_int,
      num_ext:aux.Address.num_ext,
      address_id:aux.Address.address_id,
      user_id:aux.user_id
    };
  }

  console.log(previewCustomer)



  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <CustomerEditDialogHeader id={id} />
      <CustomerEditForm
        saveCustomer={saveCustomer}
        actionsLoading={actionsLoading}
        customer={id ? previewCustomer : customersUIProps.initCustomer}
        onHide={onHide}
      />
    </Modal>
  );
}
