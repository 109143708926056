import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar, Input,
} from "../../../../../_metronic/_partials/controls";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import {InputNumber, DatePicker, Switch} from 'antd';
// import {makeStyles, useTheme} from '@material-ui/core/styles';
// import Stepper from '@material-ui/core/Stepper';
// import Step from '@material-ui/core/Step';
// import StepLabel from '@material-ui/core/StepLabel';
// import StepConnector from '@material-ui/core/StepConnector';
// import './css/makeOrder.css'
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';

import {makeStyles, useTheme} from '@material-ui/core';
import {Stepper} from '@material-ui/core';
import {Step} from '@material-ui/core';
import {StepLabel} from '@material-ui/core';
import {StepConnector} from '@material-ui/core';
import './css/makeOrder.css'
import {Button} from '@material-ui/core';
import {Typography} from '@material-ui/core';

import {PlusSquareTwoTone} from '@ant-design/icons';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/es_ES';
import {Input as InputAnt} from 'antd';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';


import {useCustomersUIContext} from "./CustomersUIContext";
import {CustomerEditDialog} from "./customer-edit-dialog/CustomerEditDialog";
import {Field, Form, Formik} from "formik";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Input as InputA, AutoComplete, Select} from 'antd';
import {CloseCircleOutlined} from '@ant-design/icons'
import {useParams} from 'react-router-dom';
import * as actions from "../../_redux/products/productsActions";
import * as actionsO from "../../_redux/orders/ordersActions";
import * as actions1 from "../../_redux/users/usersActions";
import * as actions2 from "../../_redux/customers/customersActions";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";
import {forEach} from 'react-bootstrap/cjs/ElementChildren';
import SVG from 'react-inlinesvg';
import {TextField} from '@material-ui/core';
import {SalesUtilities} from "../../../../components/dashboard/utilities/SalesUtilities";
import * as actionsUser from "../../_redux/users/usersActions";

var formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
});
const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
        margin: '0 auto',
        display: 'block'
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        fontWeight: "bold",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    connectorActive: {
        '& $connectorLine': {
            borderColor: theme.palette.secondary.main,
        },
    },
    connectorCompleted: {
        '& $connectorLine': {
            borderColor: theme.palette.primary.main,
        },
    },
    connectorDisabled: {
        '& $connectorLine': {
            borderColor: theme.palette.grey[100],
        },
    },
    connectorLine: {
        transition: theme.transitions.create('border-color'),
    },
}));

function getSteps() {
    return ['Selecciona el cliente y tu dirección', 'Selecciona los productos', 'Selecciona una fecha de entrega', 'Confirma'];
}

const pricesNames = ['Hotchianos', 'Distribudores Personal', 'Distribudores Empresarial', 'Hotchers', 'Hotchers Oro', 'Hotchers Platino', 'Externos'];


function getStepContent(step) {
    switch (step) {
        case 0:
            return 'Selecciona el cliente y su dirección';
        case 1:
            return 'Selecciona los productos';
        case 2:
            return 'Selecciona una fecha de entrega';
        case 3:
            return 'Confirma los datos';
        default:
            return 'Error';
    }
}

const mapEnumPaymentMethod = (paymentMethod) => {
    const map = {
        1: '1 - Efectivo',
        2: '2 - Tarjeta',
        3: '3 - Transferencia'
    };

    return map[paymentMethod];
};

export function MakeOrder({id}) {
    const dispatch = useDispatch();
    const customersUIContext = useCustomersUIContext();
    const {TextArea} = InputAnt;
    const {Option, OptGroup} = Select;
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [intialLoad, setIntialLoad] = React.useState(false);
    const [saveOrderAux, setSave] = React.useState(false);
    const [currentCustomer, setCurrentCustomer] = React.useState(null);
    const [invoice, setInvoice] = React.useState(false);
    const [currentUser, setCurrentUser] = React.useState(null);
    const [currentProducts, setCurrentProducts] = React.useState(null);
    const [currentPackage, setCurrentPackage] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);
    const [currentDetails, setCurrentDetails] = React.useState({date:null,comments:null,phoneNumber:null,name:null,paymentMethod:null});
    const [currentProductsAux, setCurrentProductsAux] = React.useState([]);
    const [currentAddress, setCurrentAddress] = React.useState(null);
    const [currentSearch, setCurrentSearch] = React.useState(null);
    const [currentError, setCurrentError] = React.useState(null);
    const [auxProducts, setAuxProducts] = React.useState([]);
    const [totalCart, setTotalCart] = React.useState(0);
    const [usersTeamData, setusersTeamData] = React.useState([]);
    const [totalCartDiscount, setTotalCartDiscount] = React.useState(0);
    const dataAuth = useSelector(state => state.auth);
    const orderCreated = useSelector(state => state.orders.lastOrderCreated);
    const theme1 = useTheme();
    const steps = getSteps();
    const onChangeSearch = (value) => {
        setCurrentSearch(value);
    }

    let inputFile = '';
    let formElement = '';
    const uploadClick = () => {
        inputFile.click();
        return false;
    };


    const [credit, setCredit] = React.useState({
        cvc: '',
        expiry: '',
        focus: '',
        name: '',
        number: '',
    });

    const codigosDescuento =
        {
            '4L':['H-DB14', 'H-DO14', 'H-DA14', 'H-GA14', 'H-JCI14', 'H-JCO14', 'H-JKI14', 'H-TC14', 'H-LDL14', 'H-LF14', 'H-SA14', 'H-ST14', 'H-SK14', 'H-SM14', 'H-SP14', 'H-SN14', 'H-SC14', 'H-SE14', 'H-DS14', 'H-SU14', 'H-DF14', 'H-DL14', 'H-TL14'],
            '1L':['H-DL13'],
            '60ML':['H-MDRB1', 'H-MDRO1', 'H-MDRA1', 'H-MDFV1', 'H-MJACI1', 'H-MDRA1', 'H-MJACO1', 'H-MJAMK1', 'H-MJAMC1', 'H-MLDL1', 'H-MLDF1', 'H-MSAE1', 'H-MST1', 'H-MSC1', 'H-MSM1', 'H-MSMAS1', 'H-MSN1', 'H-MSA1', 'H-MSE1', 'H-MDSC1', 'H-MS1', 'H-MTAL1'],
        };

    const handleInputFocus1 = (e) => {
        setCredit({ ...credit ,focus: e.target.name });
    }

    const handleInputChange1 = (e) => {
        const { name, value } = e.target;

        setCredit({ ...credit ,[name]: value });
    }
    const handleInputChange2 = (e) => {
        const { name, value } = e.target;
        let textTemp = value;
        if (textTemp[0] !== '1' && textTemp[0] !== '0') {
            textTemp = '';
        }
        if (textTemp.length === 2) {
            if (parseInt(textTemp.substring(0, 2)) > 12 || parseInt(textTemp.substring(0, 2)) == 0) {
                textTemp = textTemp[0];
            } else if (value.length === 2) {
                textTemp += '/';
            } else {
                textTemp = textTemp[0];
            }
        }
        setCredit({ ...credit ,[name]: textTemp });
    }
    const orderForEdit = useSelector(
        (state) => ( state.orders.orderForEdit.order),
        shallowEqual
    );

    const dataUsers = useSelector(state => state.users);

    if(dataUsers.report && dataUsers.report.teamData && dataUsers.report.teamData.length > 0 && usersTeamData.length === 0){
        let usersTeam = dataUsers.report.teamData.map((elem)=>{
            const user = {...elem.user};
            return {
                value: user.user_id + ' ' + user.name + ' ' + user.last_name_1,
                obj: user,
                label: (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            {user.name + ' ' + user.last_name_1}
                            <span>
                            {user.email}
                        </span>

                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            {user.Address.city}
                            <span>
                        {user.Address.colony}
                    </span>

                        </div>
                    </>
                )
            }
                ;
        })
        setusersTeamData(usersTeam);
    }


    if(orderForEdit && window.location.href.includes("edit") && !intialLoad){
        setIntialLoad(true);
        setCurrentCustomer(orderForEdit.Client);
        if (dataAuth.user.admin_user_id)
            setCurrentUser(orderForEdit.User);
        setCurrentAddress([{Address:orderForEdit.Address},null]);
        setCurrentDetails({date:moment(orderForEdit.Shipment.date,"YYYY-MM-DD").format('DD/MM/YYYY'),phone:orderForEdit.Shipment.phone,name:orderForEdit.Shipment.assigned_to,paymentMethod: mapEnumPaymentMethod(orderForEdit.payment_method),comments: orderForEdit.comment});
        const auxCurrentProducts = []
        const auxCurrentProductsAux = []
        const auxAuxProducts = []
        orderForEdit.Orders_Products_Relationships.forEach((elem)=>{
            let auxObject = {...elem.Product};
            auxObject.quantity = elem.quantity;
            auxObject.price_per_unit = elem.price_per_unit;
            auxCurrentProducts.push(auxObject)
            auxCurrentProductsAux.push(null)
            auxAuxProducts.push(elem.Product.sku)
        })
        setCurrentProducts(auxCurrentProducts);
        setCurrentProductsAux(auxCurrentProductsAux);
        setAuxProducts(auxAuxProducts);

    }


    useEffect(() => {
        if (dataAuth.user.admin_user_id) {
            dispatch(actions.fetchProductsAdmin(''));
            dispatch(actions1.fetchUsersAdmin(''));
            dispatch(actions2.fetchCustomersAdmin(''));
        } else {
            setCurrentUser(dataAuth.user);
            dispatch(actions1.fetchUserAddress({user_id: dataAuth.user.user_id}));
            dispatch(actionsUser.fetchUserReport(dataAuth.user.user_id));
            dispatch(actions.fetchProducts({type_user_id: dataAuth.user.type_user_id}));
            dispatch(actions2.fetchCustomers({user_id: dataAuth.user.user_id}));
        }
        if(id){
            dispatch(actionsO.fetchOrder(id)).then((response)=>{
                console.log(response)
            });
        }
    }, [])

    const currentStateProducts = useSelector(
        (state) => ({currentState: state.products}),
        shallowEqual
    );


    const currentStateCustomers = useSelector(
        (state) => ({currentState: state.customers}),
        shallowEqual
    );



    const currentStateUsers = useSelector(
        (state) => ({currentState: state.users}),
        shallowEqual
    );

    const currentListAddress = useSelector(
        (state) => (state.users.addressOrder),
        shallowEqual
    );

    const currentUserToComplete = useSelector(
        (state) => (state.users.userToComplete),
        shallowEqual
    );

    const onFlowToCompleteUser =  window.location.href.includes("newUser") && currentUserToComplete

    console.log(onFlowToCompleteUser)


    var formatter = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
    });

    const options1 = currentStateProducts.currentState.entities && currentStateProducts.currentState.entities.map((prod,i) => {
        return {
            value: prod.name + ' ' + prod.sku + i,
            obj: prod,
            label: (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {prod.sku + '  '}
                        {prod.name}
                        <span>
                            {formatter.format(prod.external_price)}
                        </span>

                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {'Stock: ' + prod.stock}
                        <span>
                        {prod.model}
                    </span>

                    </div>
                </>
            )
        }
    })
    const options2 = currentStateUsers.currentState.entities && currentStateUsers.currentState.entities.map((user,i) => {
        return {
            value: user.user_id + ' ' + user.name + ' ' + user.last_name_1,
            obj: user,
            label: (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {user.name + ' ' + user.last_name_1}
                        <span>
                            {user.email}
                        </span>

                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {user.Address.city}
                        <span>
                        {user.Address.colony}
                    </span>

                    </div>
                </>
            )
        }
    })
    const options3 = currentStateCustomers.currentState.entities && currentStateCustomers.currentState.entities.map((customer) => {
        return {
            value:  customer.client_id + ' ' + customer.name + ' ' + customer.last_name_1,
            obj: customer,
            label: (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {customer.name + ' ' + customer.last_name_1}
                        <span>
                            {customer.email}
                        </span>

                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {customer.Address.city}
                        <span>
                        {customer.Address.colony}
                    </span>

                    </div>
                </>
            )
        }
    })

    const auxFunction = (currentProducts)=>{
        let auxDiscount = 0;
        let currCount = {
            '4L':0,
            '1L':0,
            '60ML':0,
        };
        currentProducts.forEach((product)=>{
            if(codigosDescuento[product.model] && codigosDescuento[product.model].indexOf(product.sku) >= 0){
                currCount[product.model] += product.quantity;
            }
        })
        currCount["4L"]= Math.trunc(currCount["4L"]/3);
        currCount["1L"] = Math.trunc(currCount["1L"]/3);
        currCount["60ML"] = Math.trunc(currCount["60ML"]/3);

        currentProducts.sort(function(a, b) {
            return Number(a.price) - Number(b.price);
        }).forEach((product)=>{
            if(codigosDescuento[product.model] && codigosDescuento[product.model].indexOf(product.sku) >= 0){
                if(currCount[product.model] <= product.quantity){
                    auxDiscount += product.price * currCount[product.model]
                    currCount[product.model] = 0;
                } else {
                    auxDiscount += product.price * product.quantity
                    currCount[product.model] = currCount[product.model] - product.quantity;
                }
            }
        })
        return 0;
    }


    function handleNext() {
        console.log(currentProducts)
        if(activeStep === 0){
            if(!currentAddress){
                setCurrentError('Tienes que escoger una dirección');
                return false
            }
        }else if(activeStep === 1){

            if(!currentProducts && (dataAuth.user.block === 0  && !onFlowToCompleteUser)){
                setCurrentError('Tienes que escoger al menos un producto');
                return false
            }
            if(onFlowToCompleteUser){
                setCurrentProducts([
                    {
                        "product_id": 165,
                        "name": "Bio Detergente Ropa blanca",
                        "sku": "Kit",
                        "brand": "Hotch",
                        "imageUrl": "",
                        "model": "1L",
                        "external_price": 650,
                        "stock": 10000,
                        "hide": 0,
                        "createdAt": "2020-07-14",
                        "updatedAt": "2022-06-14",
                        "price": "650",
                        "quantity": 1
                    }
                ])
            }
        }else if(activeStep === 2){
            let auxTotal = !currentProducts ? 0 : currentProducts.reduce((total, curr, i) => {
                if (intialLoad && curr.price_per_unit) {
                    return total + ((curr.price_per_unit || curr.price) * curr.quantity)
                } else if (dataAuth.user.admin_user_id) {
                    let auxPrice;
                    if (currentProductsAux[i] === 6) {
                        auxPrice = curr.external_price;
                    } else {
                        auxPrice = curr.Rule_fees[currentProductsAux[i]] && curr.Rule_fees[currentProductsAux[i]].price;
                    }
                    if (curr.Rule_fees[currentProductsAux[i]] || currentProductsAux[i] === 6) {
                        if (invoice) {
                            return total + (auxPrice * curr.quantity )
                        } else {
                            return total + (auxPrice * curr.quantity)
                        }
                    } else {
                        return total + 0
                    }
                } else {
                    if (invoice) {
                        return total + ((curr.price || curr.price_per_unit) * curr.quantity)
                    } else {
                        return total + ((curr.price || curr.price_per_unit) * curr.quantity)
                    }
                }
            }, 0)
            setTotalCart(auxTotal);
            let auxDiscount = 0;
            if(currentProducts){
                let currCount = {
                    '4L':0,
                    '1L':0,
                    '60ML':0,
                };
                currentProducts.forEach((product)=>{
                    if(codigosDescuento[product.model] && codigosDescuento[product.model].indexOf(product.sku) >= 0){
                        currCount[product.model] += product.quantity;
                    }
                })
                currCount["4L"]= Math.trunc(currCount["4L"]/3);
                currCount["1L"] = Math.trunc(currCount["1L"]/3);
                currCount["60ML"] = Math.trunc(currCount["60ML"]/3);

                currentProducts.sort(function(a, b) {
                    return Number(a.price) - Number(b.price);
                }).forEach((product)=>{
                    if(codigosDescuento[product.model] && codigosDescuento[product.model].indexOf(product.sku) >= 0){
                        if(currCount[product.model] <= product.quantity){
                            auxDiscount += product.price * currCount[product.model]
                            currCount[product.model] = 0;
                        } else {
                            auxDiscount += product.price * product.quantity
                            currCount[product.model] = currCount[product.model] - product.quantity;
                        }
                    }
                })
                setTotalCartDiscount(0);
            }
            if(!currentDetails.date || !currentDetails.phoneNumber || !currentDetails.name || !currentDetails.paymentMethod){
                setCurrentError('Tienes que llenar todos los datos de entrega');
                return false
            }
        }
        setCurrentError(null);
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    const btnRef = useRef();


    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    function handleReset() {
        setCurrentProductsAux([])
        setCurrentProducts([])
        setAuxProducts([])
        setActiveStep(0);
        setCurrentCustomer(null)
        setCurrentDetails({date:null,comments:null,phoneNumber:null,name:null,paymentMethod:null})
        setCurrentAddress(null)
    }

    function onChange(value, y) {
        let auxArray = currentProducts.map((pro) => {
            if (pro.sku === y.sku) {
                let auxObj = {...pro}
                auxObj.quantity = value;
                return auxObj
            } else {
                return pro
            }
        })
        setCurrentProducts(auxArray)
    }

    const connector = (
        <StepConnector
            classes={{
                active: classes.connectorActive,
                completed: classes.connectorCompleted,
                disabled: classes.connectorDisabled,
                line: classes.connectorLine,
            }}
        />
    );

    function range(start, end) {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }

    function disabledDate(current) {
        // Can not select days before today and today
        let format1 = 'hh:mm:ss'
        let beforeTime = moment('00:00:00', format1);
        let afterTime = moment('15:00:00', format1);
        current.format('D');
        let auxNumber = moment().isBetween(beforeTime, afterTime) ? 1 : 2;
        return current && (current.dayOfYear() < moment().endOf('day').dayOfYear() + auxNumber || moment(current).day() === 0 || moment(current).day() === 6 || (moment(current).day() === 1 && current.dayOfYear() - 2 <= moment().endOf('day').dayOfYear() && (moment().endOf('day').day() === 5 || moment().endOf('day').day() === 6 )));
    }

    function saveData(){
        sendData(true)
    }

    function sendData(auxSave = false) {
        let x = new Date;
        let total = currentProducts && currentProducts.reduce((total, curr, i) => {
            if (dataAuth.user.admin_user_id) {
                let auxPrice;
                if (currentProductsAux[i] === 6) {
                    auxPrice = curr.external_price;
                } else {
                    auxPrice = curr.Rule_fees[currentProductsAux[i]] && curr.Rule_fees[currentProductsAux[i]].price;
                }
                if (curr.Rule_fees[currentProductsAux[i]] || currentProductsAux[i] === 6) {
                    if (invoice) {
                        return total + (auxPrice * curr.quantity)
                    } else {
                        return total + (auxPrice * curr.quantity)
                    }
                } else {
                    return total + 0
                }
            } else {
                if (invoice) {
                    return total + (curr.price * curr.quantity)
                } else {
                    return total + (curr.price * curr.quantity)
                }
            }
        }, 0)
        dispatch(actionsO.createOrder({
            order:{
                initialDate:moment(x).format("YYYY-MM-DD"),
                address_id:currentAddress[0].address_id,
                client_id:currentCustomer ? currentCustomer.client_id: null,
                user_id:currentUser.user_id,
                comment:currentDetails.comments,
                total:total - totalCartDiscount,
                invoice:invoice ?  1: 0,
                payment_method:currentDetails.paymentMethod,
                // unblockUser: onFlowToCompleteUser ? onFlowToCompleteUser : null,
                status:1,
                saveOrder: auxSave ? 1 : 0,
            },
            products:currentProducts,
            shipment:{
                type:1,
                date:moment(currentDetails.date,'DD/MM/YYYY').format("YYYY-MM-DD"),
                phone:currentDetails.phoneNumber,
                name:currentDetails.name,
            },
            edit:intialLoad
        })).then((data) =>
            {

                // if(currentDetails.paymentMethod === 3){
                //     updateFile()
                // }
                handleNext()
            }

        );
    }

    function disabledDateTime() {
        return {
            disabledHours: () => range(0, 10).concat(range(17, 24))
        };
    }

    const [data, setData] = useState(null);
    const [loadedMessage, setLoadedMessage] = useState("");



    const updateFile = e => {
        e.preventDefault();
        sendData()
        setData(null)
        const orderCreated = localStorage.getItem('currentOrderId');

        const formData = new FormData(formElement)
        dispatch(actions1.unblockUser(id));


        fetch(`https://hbplat.serv-lab.com/api/private/uploadReceipt/${orderCreated}`, {
            method: 'POST',
            body: formData,
            headers: new Headers({
                'Authorization': 'Bearer '+ dataAuth.authToken,
            }),
        }).then((res)=>{
            res.json().then((x)=>{
                console.log(x)
                if(x.status === 200){
                    setLoadedMessage("Imagen subida correctamente")
                    setData(x.changes)
                }else{
                    setLoadedMessage("La imagen no pudo ser actualizada, verifica que no pese más de 25MB")
                }
            })
        })
            .then((data)=>{
                console.log(data)
            })

        return false;
    };

    const format = 'HH:mm';
    const allNull = () => {
        let answer = true;
        currentProductsAux.forEach((e,i)=>{
            if(e !== null && currentProducts[i].price_per_unit){
                answer = false;
            }
        })
        return answer;
    };
    return (
        <>
            <form ref={form => {
                // assigns a reference so we can trigger it later
                formElement = form;
            }} id='updateForm' style={{margin:'10px 0', display: 'none'}} method="post" action={`https://hbplat.serv-lab.com/api/private/uploadReceipt/${id}`}>
                <input ref={input => {
                    // assigns a reference so we can trigger it later
                    inputFile = input;
                }} onChange={sendData} accept="image/*" type="file" id="myFile" name="imagen" />
                <input type="submit"  className="btn btn-primary" />
            </form>
            {currentUser && <CustomerEditDialog show={showModal} user_id={currentUser && currentUser.user_id} onHide={()=>setShowModal(false)} title="Registrar orden"/>}
            <Card>
            <CardHeader title="Levantar orden"/>
            <CardBody class="test" fit={true}>
                <div className={classes.root}>
                    <Stepper style={{overflowY: 'auto'}} activeStep={activeStep} connector={connector}>
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Formik
                        enableReinitialize={true}
                        // initialValues={product}
                        // validationSchema={ProductEditSchema}
                        onSubmit={(values) => {
                            // saveProduct(values);
                        }}
                    >
                        {({handleSubmit}) => (
                            <>
                                <Form className="form form-label-right">
                                    {
                                        activeStep === 0 &&
                                        (<div style={{width: '90%', display: 'block', margin: '0 auto'}}>
                                            <h2 style={{textAlign: 'center', margin: '35px 0'}}
                                                className="card-title font-weight-bolder">Configuración de cliente </h2>
                                            <p style={{textAlign: 'center'}}> Bienvenido al proceso de levantamiento de
                                                orden <br/> Por favor introduce los detalles referentes al cliente y a
                                                la dirección de entrega </p>
                                            <div className="form-group row">
                                                <div style={{margin: '25px 0'}} className="col-xs-12 col-md-12">
                                                    <AutoComplete
                                                        dropdownClassName="certain-category-search-dropdown"
                                                        dropdownMatchSelectWidth={500}
                                                        style={{
                                                            width: '100%',
                                                            margin: '0 auto',
                                                            display: 'block',
                                                            visibility: (dataAuth.user.admin_user_id || usersTeamData.length > 0 ? 'visible' : 'hidden')
                                                        }}
                                                        filterOption={(inputValue, option) =>
                                                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                        }
                                                        onSelect={(x, y) => {
                                                            setCurrentAddress(null);
                                                            setCurrentUser(null);
                                                            setCurrentUser(y.obj);
                                                            dispatch(actions1.fetchUserAddress({user_id: y.obj.user_id}));
                                                        }}
                                                        options={options2 || usersTeamData}
                                                    >
                                                        <InputA.Search size="large" placeholder="Busqueda de usuarios"/>
                                                    </AutoComplete>


                                                    {
                                                        currentUser && (
                                                            <>
                                                                <div style={{
                                                                    margin: "35px 0px",
                                                                    background: "whitesmoke",
                                                                    padding: "15px 0",
                                                                    borderRadius: "20px",
                                                                }}>
                                                                    {dataAuth.user.admin_user_id && <CloseCircleOutlined
                                                                        style={{
                                                                            position: "absolute",
                                                                            right: "25px",
                                                                            fontSize: "medium",
                                                                            fontWeight: "bold",
                                                                            color: theme1.palette.primary.main,
                                                                            cursor: "pointer"
                                                                        }}

                                                                        onClick={()=>{
                                                                            setCurrentUser(null);
                                                                        }}

                                                                    />}

                                                                    <h4 style={{
                                                                        display: 'inline-block',
                                                                        marginLeft: '35px',
                                                                        color: theme1.palette.primary.main,
                                                                    }}
                                                                        className="card-title font-weight-bolder ">Usuario:</h4>
                                                                    <p style={{
                                                                        display: 'inline-block',
                                                                        fontSize: '15px',
                                                                        marginLeft: '25px'
                                                                    }}>{currentUser.name + ' ' + currentUser.last_name_1}</p>
                                                                    <br/>
                                                                    <h4 style={{
                                                                        display: 'inline-block',
                                                                        marginLeft: '35px',
                                                                        color: theme1.palette.primary.main,
                                                                    }} className="card-title font-weight-bolder ">Correo
                                                                        usuario:</h4>
                                                                    <p style={{
                                                                        display: 'inline-block',
                                                                        fontSize: '15px',
                                                                        marginLeft: '25px'
                                                                    }}> {currentUser.email}</p>
                                                                    <br/>
                                                                    <h4 style={{
                                                                        display: 'inline-block',
                                                                        marginLeft: '35px',
                                                                        color: theme1.palette.primary.main,
                                                                    }} className="card-title font-weight-bolder ">TIpo
                                                                        de socio:</h4>
                                                                    <p style={{
                                                                        display: 'inline-block',
                                                                        fontSize: '15px',
                                                                        marginLeft: '25px'
                                                                    }}>{currentUser.Type_user.label}</p>
                                                                    <br/>
                                                                    <span style={{
                                                                        float: 'right',
                                                                        color: theme1.palette.secondary.main,
                                                                        position: 'relative',
                                                                        bottom: '20px',
                                                                        right: '15px'
                                                                    }}
                                                                          className="mt-3 font-weight-bold font-size-sm">
                                                                        {currentUser && dataAuth.user.admin_user_id && 'Usuario seleccionado'}
                                                                        {currentUser && !dataAuth.user.admin_user_id && 'Usuario actual'}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                                {/*<div style={{margin: '25px 0'}} className="col-xs-12 col-md-6">*/}
                                                {/*    <AutoComplete*/}
                                                {/*        dropdownClassName="certain-category-search-dropdown"*/}
                                                {/*        dropdownMatchSelectWidth={500}*/}
                                                {/*        style={{width: '100%', margin: '0 auto', display: 'block'}}*/}
                                                {/*        filterOption={(inputValue, option) =>*/}
                                                {/*            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1*/}
                                                {/*        }*/}
                                                {/*        onSelect={(x, y) => {*/}
                                                {/*            setCurrentCustomer(y.obj);*/}

                                                {/*        }}*/}
                                                {/*        options={options3}*/}
                                                {/*    >*/}
                                                {/*    <InputA.Search size="large" placeholder="Busqueda de clientes"/>*/}
                                                {/*    </AutoComplete>*/}
                                                {/*    {currentCustomer && (*/}
                                                {/*        <>*/}
                                                {/*            <div style={{*/}
                                                {/*                margin: "35px 0px",*/}
                                                {/*                background: "whitesmoke",*/}
                                                {/*                padding: "15px 0",*/}
                                                {/*                borderRadius: "20px",*/}
                                                {/*            }}>*/}
                                                {/*                <CloseCircleOutlined*/}
                                                {/*                    style={{*/}
                                                {/*                        position: "absolute",*/}
                                                {/*                        right: "25px",*/}
                                                {/*                        fontSize: "medium",*/}
                                                {/*                        fontWeight: "bold",*/}
                                                {/*                        color: theme1.palette.primary.main,*/}
                                                {/*                        cursor: "pointer"*/}
                                                {/*                    }}*/}

                                                {/*                    onClick={()=>{*/}
                                                {/*                        setCurrentCustomer(null);*/}
                                                {/*                    }}*/}

                                                {/*                />*/}
                                                {/*                <h4 style={{*/}
                                                {/*                    display: 'inline-block',*/}
                                                {/*                    marginLeft: '35px',*/}
                                                {/*                    color: theme1.palette.primary.main,*/}
                                                {/*                }}*/}
                                                {/*                    className="card-title font-weight-bolder ">Cliente:</h4>*/}
                                                {/*                <p style={{*/}
                                                {/*                    display: 'inline-block',*/}
                                                {/*                    fontSize: '15px',*/}
                                                {/*                    marginLeft: '25px'*/}
                                                {/*                }}>{currentCustomer.name + ' ' + currentCustomer.last_name_1}</p>*/}
                                                {/*                <br/>*/}
                                                {/*                <h4 style={{*/}
                                                {/*                    display: 'inline-block',*/}
                                                {/*                    marginLeft: '35px',*/}
                                                {/*                    color: theme1.palette.primary.main,*/}
                                                {/*                }} className="card-title font-weight-bolder ">Correo*/}
                                                {/*                    usuario:</h4>*/}
                                                {/*                <p style={{*/}
                                                {/*                    display: 'inline-block',*/}
                                                {/*                    fontSize: '15px',*/}
                                                {/*                    marginLeft: '25px'*/}
                                                {/*                }}> {currentCustomer.email}</p>*/}
                                                {/*                <br/>*/}
                                                {/*                <h4 style={{*/}
                                                {/*                    display: 'inline-block',*/}
                                                {/*                    marginLeft: '35px',*/}
                                                {/*                    color: theme1.palette.primary.main,*/}
                                                {/*                }}*/}
                                                {/*                    className="card-title font-weight-bolder ">Dirección:</h4>*/}
                                                {/*                <p style={{*/}
                                                {/*                    display: 'inline-block',*/}
                                                {/*                    fontSize: '15px',*/}
                                                {/*                    marginLeft: '25px'*/}
                                                {/*                }}>{currentCustomer.Address.state + ', ' + currentCustomer.Address.city + ', C.P. ' + currentCustomer.Address.postal_code}</p>*/}
                                                {/*                <br/>*/}
                                                {/*                <span style={{*/}
                                                {/*                    float: 'right',*/}
                                                {/*                    color: theme1.palette.secondary.main,*/}
                                                {/*                    position: 'relative',*/}
                                                {/*                    bottom: '20px',*/}
                                                {/*                    right: '15px'*/}
                                                {/*                }}*/}
                                                {/*                      className="mt-3 font-weight-bold font-size-sm">*/}
                                                {/*                    Cliente seleccionado*/}
                                                {/*                </span>*/}
                                                {/*            </div>*/}
                                                {/*        </>*/}
                                                {/*    )*/}
                                                {/*    }*/}
                                                {/*</div>*/}
                                            </div>
                                            {currentUser && <div>
                                                <h2 style={{textAlign: 'center', margin: '35px 0'}}
                                                    className="card-title font-weight-bolder">Configuración de
                                                    dirección</h2>
                                                <p style={{textAlign: 'center'}}> Por favor selecciona la dirección a la
                                                    cuál se te enviarán los productos.</p>
                                                <div className="form-group row">
                                                    {/*C9F7F5*/}
                                                    {
                                                        currentListAddress &&
                                                        currentListAddress.map((item, ind) => {
                                                            return (
                                                                <div
                                                                    key={ind + "st1"}
                                                                    style={{
                                                                    margin: '25px 0',
                                                                    padding: '25px 10px',
                                                                    border: '1px',
                                                                    borderStyle: 'dotted',
                                                                    borderColor: 'black',
                                                                    background: (currentAddress && currentAddress[1] === ind) ? '#C9F7F5' : ''
                                                                }}
                                                                     onClick={() => {
                                                                         setCurrentAddress([item, ind])
                                                                     }}
                                                                     className="col-xs-6 col-md-3 selected-address">
                                                                    <span style={{
                                                                        color: theme1.palette.secondary.main,
                                                                    }}
                                                                          className="mt-3 font-weight-bold font-size-sm">
                                                                        Dirección {ind + 1}
                                                                        <br/>
                                                                    </span>
                                                                    {item.Address.street + ' num.' + item.Address.num_ext + ' ' + item.Address.num_int}
                                                                    <br/>
                                                                    {item.Address.colony}
                                                                    <br/>
                                                                    {item.Address.city + ' ' + item.Address.postal_code}
                                                                    <br/>
                                                                    {item.Address.state}
                                                                    <br/>
                                                                </div>
                                                            )

                                                        })
                                                    }
                                                    {
                                                        currentListAddress &&
                                                        (
                                                            <div style={{
                                                                margin: '25px 0',
                                                                padding: '25px 10px',
                                                                border: '1px',
                                                                borderStyle: 'dotted',
                                                                borderColor: 'black',
                                                                width: '100%'
                                                            }}
                                                                 onClick={() => {
                                                                     setShowModal(true);
                                                                     setCurrentAddress(null)
                                                                 }}
                                                                 className="col-xs-6 col-md-3 selected-address">
                                                                <PlusSquareTwoTone
                                                                    twoToneColor={theme1.palette.secondary.main}
                                                                    style={{
                                                                        fontSize: '40px',
                                                                        textAlign: 'center',
                                                                        width: '100%',
                                                                        margin: '15px 0'
                                                                    }}/>
                                                                <p style={{textAlign: 'center'}}>Agregar Dirección</p>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>}
                                        </div>)
                                    }
                                    {
                                        activeStep === 1 && (
                                            <>
                                                <h2 style={{textAlign: 'center', margin: '35px 0'}}
                                                    className="card-title font-weight-bolder">Carrito</h2>
                                                {onFlowToCompleteUser && (<>
                                                    <p style={{textAlign: 'center'}}>
                                                        Para poder continuar usando la plataforma
                                                        es necesario que adquieras uno de los kits Hotchers
                                                    </p>
                                                    <div className="row">
                                                        <div onClick={()=>setCurrentPackage(0)} className="col-12 col-md-6">
                                                            <div
                                                                style={{
                                                                    cursor:'pointer',
                                                                    background: (currentPackage === 0) ? '#C9F7F5' : ''
                                                                }}
                                                                className="card">
                                                                <h2 style={{textAlign: 'center', margin: '35px 0'}}
                                                                    className="card-title font-weight-bolder">Kit Productos $650</h2>

                                                                <div style={{margin: '20px 0'}} className="row">
                                                                    <div className="col-6">
                                                                        1. 15 productos
                                                                        <br/>
                                                                        2. Catalogo.
                                                                        <br/>
                                                                        3. Atomizadores (2)
                                                                        <br/>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        4. Flyers. (50piezas)
                                                                        <br/>
                                                                        5. Fichas de presentación.
                                                                        <br/>
                                                                        *Envio gratis*
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div onClick={()=>setCurrentPackage(1)} className="col-12 col-md-6">
                                                            <div
                                                                style={{
                                                                    cursor:'pointer',
                                                                    background: (currentPackage === 1) ? '#C9F7F5' : ''
                                                                }}
                                                                className="card">
                                                                <h2 style={{textAlign: 'center', margin: '35px 0'}}
                                                                    className="card-title font-weight-bolder">Kit Material Ventas $199</h2>

                                                                <div style={{margin: '20px 0'}} className="row">
                                                                    <div className="col-6">
                                                                        1. 15 productos
                                                                        <br/>
                                                                        2. 3 Muestras.
                                                                        <br/>
                                                                        3. Bolsa ecológica Hotch
                                                                        <br/>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <br/>
                                                                        *Envió gratis solo en Ciudad de Mexico*
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>)}

                                                {((dataAuth.user.block === 0  && !onFlowToCompleteUser) || dataAuth.user.admin_user_id ) && (<>
                                                    <p style={{textAlign: 'center'}}> Por favor selecciona los productos que
                                                        deseas adquirir</p>
                                                    {currentProducts && (<div>
                                                        <div className="row" style={{
                                                            fontWeight: 'bold',
                                                            margin: '10px 0',
                                                            padding: '10px 0',
                                                            background: 'whitesmoke',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}>
                                                            <div className="col-4 col-md-2 text-center nav-bold">
                                                                Img.
                                                            </div>
                                                            <div className="col-4 col-md-3 text-center">
                                                                Nombre/SKU
                                                            </div>
                                                            <div className="col-4 col-md-1 text-center">
                                                                Modelo
                                                            </div>
                                                            <div className="col-4 col-md-2 text-center">
                                                                Precio
                                                            </div>
                                                            <div className="col-3 col-md-1 text-center">
                                                                Cantidad
                                                            </div>
                                                            <div className="col-3 col-md-2 text-center">
                                                                Total
                                                            </div>
                                                            <div className="col-2 col-md-1 text-center">
                                                                Borrar
                                                            </div>
                                                        </div>
                                                        {currentProducts.map((prod, index) => {
                                                            return (
                                                                <div key={index} className="row" style={{
                                                                    fontWeight: 'bold',
                                                                    margin: '10px 0',
                                                                    padding: '10px 0',
                                                                    alignItems: 'center',
                                                                }}>
                                                                    <div className="col-4 col-md-2 text-center nav-bold">
                                                                        <img
                                                                            style={{
                                                                                width: 'auto',
                                                                                maxHeight: '80px'
                                                                            }}
                                                                            onError={toAbsoluteUrl("/media/products/placeholder.jpg")}
                                                                            src={toAbsoluteUrl("/media/products/" + (prod.imageUrl !== null ? prod.imageUrl : "placeholder.jpg"))}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div className="col-4 col-md-3 text-center">
                                                                        {prod.name}
                                                                        <br/>
                                                                        {prod.sku}
                                                                    </div>
                                                                    <div className="col-4 col-md-1 text-center">
                                                                        {prod.model}
                                                                    </div>
                                                                    <div className="col-4 col-md-2 text-center">
                                                                        {
                                                                            dataAuth.user.admin_user_id ?
                                                                                <Select onSelect={(value) => {
                                                                                    let auxArray = [...currentProductsAux];
                                                                                    let auxProductsArray = [...currentProducts];
                                                                                    auxProductsArray[index].price = auxProductsArray[index].Rule_fees[value].price;
                                                                                    auxArray[index] = value;
                                                                                    setCurrentProductsAux(auxArray);
                                                                                }} style={{width: 120}}>
                                                                                    {prod.Rule_fees.map((item, i) => {
                                                                                        return (
                                                                                            <OptGroup
                                                                                                key={i + "fees"}
                                                                                                label={pricesNames[i]}>
                                                                                                <Option
                                                                                                    value={i}>
                                                                                                    {formatter.format(item.price)}
                                                                                                </Option>
                                                                                            </OptGroup>
                                                                                        )
                                                                                    })}
                                                                                    <OptGroup
                                                                                        label={pricesNames[6]}>
                                                                                        <Option
                                                                                            value={6}>
                                                                                            {formatter.format(prod.external_price)}
                                                                                        </Option>
                                                                                    </OptGroup>
                                                                                </Select>
                                                                                : formatter.format(prod.price)
                                                                        }

                                                                    </div>
                                                                    <div className="col-3 col-md-1 text-center">
                                                                        <InputNumber style={{textAlign: 'center'}} min={1}
                                                                                     max={prod.stock}
                                                                                     key={prod.sku + "inputdata"}
                                                                                     defaultValue={prod.quantity}
                                                                                     onChange={(value) => {
                                                                                         onChange(value, prod)
                                                                                     }}/>
                                                                    </div>
                                                                    <div className="col-3 col-md-2 text-center">
                                                                        {dataAuth.user.admin_user_id && currentProductsAux[index] === 6 && formatter.format(prod.external_price * prod.quantity)}
                                                                        {dataAuth.user.admin_user_id && currentProductsAux[index] !== 6 && prod.Rule_fees[currentProductsAux[index]] && formatter.format(prod.Rule_fees[currentProductsAux[index]].price * prod.quantity)}
                                                                        {dataAuth.user.admin_user_id && intialLoad && allNull() && prod.price_per_unit && formatter.format(prod.price_per_unit * prod.quantity)}
                                                                        {!dataAuth.user.admin_user_id && formatter.format((prod.price || prod.price_per_unit) * prod.quantity)}
                                                                    </div>
                                                                    <div className="col-2 col-md-1  text-center">
                                                                        <a
                                                                            title="Borrado de orden"
                                                                            onClick={()=>{
                                                                                let aux1 = currentProducts.slice()
                                                                                let aux2 = auxProducts.slice()
                                                                                let aux3 = currentProductsAux.slice()
                                                                                aux1.splice(index,1)
                                                                                aux2.splice(index,1)
                                                                                aux3.splice(index,1)
                                                                                setCurrentProducts(aux1)
                                                                                setAuxProducts(aux2)
                                                                                setCurrentProductsAux(aux3)
                                                                                return true
                                                                            }}
                                                                            className="btn btn-icon btn-light btn-hover-danger btn-sm"

                                                                        >
                                                                            <span className="svg-icon svg-icon-md svg-icon-danger">
                                                                                <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')}/>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                            )
                                                        })}
                                                        <div className="row" style={{
                                                            fontWeight: 'bold',
                                                            margin: '10px 0',
                                                            padding: '10px 0',
                                                        }}>
                                                            <div style={{
                                                                textAlign: 'right',
                                                                width: '100%',
                                                                fontWeight: 'bold',
                                                                fontSize: '20px',
                                                            }}>
                                                                Total:
                                                                <span style={{color: theme1.palette.primary.main}}>
                                                                    {formatter.format(currentProducts.reduce((total, curr, i) => {
                                                                        if (intialLoad && allNull()) {
                                                                            return total + ((curr.price_per_unit || curr.price) * curr.quantity)
                                                                        } else if(dataAuth.user.admin_user_id){
                                                                            let auxPrice;
                                                                            if (currentProductsAux[i] === 6) {
                                                                                auxPrice = curr.external_price;
                                                                            } else {
                                                                                auxPrice = curr.Rule_fees[currentProductsAux[i]] && curr.Rule_fees[currentProductsAux[i]].price;
                                                                            }
                                                                            if (curr.Rule_fees[currentProductsAux[i]] || currentProductsAux[i] === 6) {
                                                                                if (invoice) {
                                                                                    return total + (auxPrice * curr.quantity)
                                                                                } else {
                                                                                    return total + (auxPrice * curr.quantity)
                                                                                }
                                                                            } else {
                                                                                return total + 0
                                                                            }
                                                                        } else {
                                                                            if (invoice) {
                                                                                return total + (curr.price * curr.quantity)
                                                                            } else {
                                                                                return total + (curr.price * curr.quantity)
                                                                            }
                                                                        }

                                                                    }, 0) )}
                                                                    {invoice && ' con IVA'}
                                                                </span>
                                                                {
                                                                    auxFunction(currentProducts) > 0 && <>
                                                                        <br/>
                                                                        Descuento Buen fin:
                                                                       <span style={{color: 'red'}}>
                                                                         {formatter.format(auxFunction(currentProducts))}
                                                                           {invoice && ' con IVA'}
                                                                            </span>
                                                                        </>
                                                                }
                                                            </div>
                                                        </div>


                                                        </div>)}
                                                    <div className="form-group row">
                                                        <div className="col-lg-12">
                                                            <AutoComplete
                                                                dropdownClassName="certain-category-search-dropdown"
                                                                dropdownMatchSelectWidth={500}
                                                                style={{width: '100%', margin: '0 auto', display: 'block'}}
                                                                filterOption={(inputValue, option) =>
                                                                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                                }
                                                                onChange={onChangeSearch}
                                                                value={currentSearch}
                                                                onSelect={(x, y) => {
                                                                    if (currentProducts) {
                                                                        if (auxProducts.indexOf(y.obj.sku) < 0) {
                                                                            setCurrentProducts(currentProducts.concat([{
                                                                                ...y.obj,
                                                                                quantity: 1
                                                                            }]))
                                                                            setCurrentProductsAux(currentProductsAux.concat([null]));
                                                                            setAuxProducts(auxProducts.concat([y.obj.sku]));
                                                                        }
                                                                    } else {
                                                                        setCurrentProductsAux(currentProductsAux.concat([null]));
                                                                        setAuxProducts(auxProducts.concat([y.obj.sku]));
                                                                        setCurrentProducts([{...y.obj, quantity: 1}]);
                                                                    }
                                                                    setCurrentSearch('');
                                                                }}
                                                                options={options1}
                                                            >
                                                                <InputA.Search size="large"
                                                                               placeholder="Busqueda productos"/>
                                                            </AutoComplete>
                                                        </div>
                                                    </div>
                                                </>)}

                                            </>
                                        )
                                    }
                                    {
                                        activeStep === 2 && (<>
                                            <h2 style={{textAlign: 'center', margin: '35px 0'}}
                                                className="card-title font-weight-bolder">Configuración de datos de
                                                entrega</h2>
                                            <p style={{textAlign: 'center'}}> Por favor introduce los datos referentes a
                                                la entrega del pedido</p>
                                            <div className="form-group row">

                                                <div className="col-5 offset-1">
                                                    <h5 style={{
                                                        textAlign: 'left',
                                                        marginBottom: '20px',
                                                        marginTop: '20px'
                                                    }}
                                                        className="font-weight-bolder">Fecha de entrega</h5>
                                                    <DatePicker
                                                        disabledDate={disabledDate}
                                                        locale={locale}

                                                        style={{width: '100%'}}
                                                        formar='MM-DD-YYYY hh:mm'
                                                        disabledTime={disabledDateTime}
                                                        onChange={(value) => {
                                                            let auxObj = {...currentDetails}
                                                            auxObj.date = value.format("DD MM YYYY");
                                                            setCurrentDetails(auxObj);
                                                        }}/>
                                                </div>
                                                <div className="col-5"
                                                     style={{marginBottom: '20px', marginTop: '20px'}}>
                                                    <h5 style={{textAlign: 'left', margin: '5px 0'}}
                                                        className="font-weight-bolder">Forma de pago</h5>
                                                    <Select onSelect={(value) => {
                                                        let auxObj = {...currentDetails};
                                                        auxObj.paymentMethod = value;
                                                        setCurrentDetails(auxObj);
                                                    }} style={{width: '100%'}}
                                                        defaultValue={currentDetails.paymentMethod}
                                                    >
                                                        <Option value={1}>
                                                            1 - Efectivo
                                                        </Option>
                                                        <Option value={2}>
                                                            2 - Tarjeta de débito o credito
                                                        </Option>
                                                        <Option value={3}>
                                                            2 - Transferencia
                                                        </Option>
                                                    </Select>
                                                </div>
                                                <div className="col-5 offset-1"
                                                     style={{marginBottom: '20px', marginTop: '20px'}}>
                                                    <h5 style={{textAlign: 'left', margin: '5px 0'}}
                                                        className="font-weight-bolder">Nombre de quien recibe</h5>
                                                    <InputAnt onChange={(e) => {
                                                        const { value } = e.target;
                                                        let auxObj = {...currentDetails};
                                                        auxObj.name = value;
                                                        setCurrentDetails(auxObj);
                                                    }}
                                                    defaultValue={currentDetails.name}
                                                    style={{width: '100%'}}/>
                                                </div>
                                                <div className="col-5"
                                                     style={{marginBottom: '20px', marginTop: '20px'}}>
                                                    <h5 style={{textAlign: 'left', margin: '5px 0'}}
                                                        className="font-weight-bolder">Número de contacto</h5>
                                                    <InputAnt onChange={(e) => {
                                                        const { value } = e.target;
                                                        let auxObj = {...currentDetails}
                                                        auxObj.phoneNumber = value;
                                                        setCurrentDetails(auxObj);
                                                      }}
                                                    defaultValue={currentDetails.phone}
                                                    style={{width: '100%'}}/>
                                                </div>
                                                <div className="col-10 offset-1">
                                                    <h5 style={{
                                                        textAlign: 'left',
                                                        marginBottom: '20px',
                                                        marginTop: '20px'
                                                    }}
                                                        className="font-weight-bolder">Indicaciones extra</h5>
                                                    <TextArea
                                                        onChange={(e) => {
                                                            const { value } = e.target;
                                                            let auxObj = {...currentDetails}
                                                            auxObj.comments = value;
                                                            setCurrentDetails(auxObj);
                                                        }}
                                                        setCurrentDetails={orderForEdit && orderForEdit.comment}
                                                        style={{width: '100%'}}/>
                                                </div>
                                                <div className="col-10 offset-1">

                                                </div>
                                            </div>
                                        </>)
                                    }
                                    {
                                        activeStep === 3 && (<>
                                            <h2 style={{textAlign: 'center', margin: '35px 0'}}
                                                className="card-title font-weight-bolder">Confirma los datos</h2>
                                            {currentProducts && (<div>
                                                <div className="row" style={{
                                                    fontWeight: 'bold',
                                                    margin: '10px 0',
                                                    padding: '10px 0',
                                                    background: 'whitesmoke',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <div className="col-4 col-md-2 text-center nav-bold">
                                                        Img.
                                                    </div>
                                                    <div className="col-4 col-md-3 text-center">
                                                        Nombre/SKU
                                                    </div>
                                                    <div className="col-4 col-md-1 text-center">
                                                        Modelo
                                                    </div>
                                                    <div className="col-4 col-md-2 text-center">
                                                        Precio
                                                    </div>
                                                    <div className="col-4 col-md-2 text-center">
                                                        Cantidad
                                                    </div>
                                                    <div className="col-4 col-md-2 text-center">
                                                        Total
                                                    </div>
                                                </div>
                                                {currentProducts.map((prod, index) => {
                                                    return (
                                                        <div className="row" style={{
                                                            fontWeight: 'bold',
                                                            margin: '10px 0',
                                                            padding: '10px 0',
                                                            alignItems: 'center',
                                                        }}>
                                                            <div className="col-4 col-md-2 text-center nav-bold">
                                                                <img
                                                                    style={{
                                                                        width: 'auto',
                                                                        maxHeight: '80px'
                                                                    }}

                                                                    onError={toAbsoluteUrl("/media/products/placeholder.jpg")}
                                                                    src={toAbsoluteUrl("/media/products/" + prod.imageUrl)}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="col-4 col-md-3 text-center">
                                                                {prod.name}
                                                                <br/>
                                                                {prod.sku}
                                                            </div>
                                                            <div className="col-4 col-md-1 text-center">
                                                                {prod.model}
                                                            </div>
                                                            <div className="col-4 col-md-2 text-center">
                                                                {dataAuth.user.admin_user_id && currentProductsAux[index] === 6 && formatter.format(prod.external_price)}
                                                                {dataAuth.user.admin_user_id && currentProductsAux[index] !== 6 && prod.Rule_fees[currentProductsAux[index]] && formatter.format(prod.Rule_fees[currentProductsAux[index]].price)}
                                                                {dataAuth.user.admin_user_id && intialLoad && allNull() && prod.price_per_unit && formatter.format(prod.price_per_unit)}
                                                                {!dataAuth.user.admin_user_id && formatter.format((prod.price || prod.price_per_unit))}
                                                            </div>
                                                            <div className="col-4 col-md-2 text-center">
                                                                {prod.quantity}
                                                            </div>
                                                            <div className="col-4 col-md-2 text-center" style={{color: theme1.palette.primary.main}}>
                                                                {dataAuth.user.admin_user_id && currentProductsAux[index] === 6 && formatter.format(prod.external_price * prod.quantity)}
                                                                {dataAuth.user.admin_user_id && currentProductsAux[index] !== 6 && prod.Rule_fees[currentProductsAux[index]] && formatter.format(prod.Rule_fees[currentProductsAux[index]].price * prod.quantity)}
                                                                {dataAuth.user.admin_user_id && intialLoad && allNull() && prod.price_per_unit && formatter.format(prod.price_per_unit * prod.quantity)}
                                                                {!dataAuth.user.admin_user_id && formatter.format((prod.price || prod.price_per_unit) * prod.quantity)}
                                                            </div>
                                                        </div>

                                                    )
                                                })}
                                                <div className="row" style={{
                                                    fontWeight: 'bold',
                                                    margin: '10px 0',
                                                    padding: '10px 0',
                                                }}>
                                                    <div style={{
                                                        textAlign: 'right',
                                                        width: '100%',
                                                        fontWeight: 'bold',
                                                        fontSize: '20px',
                                                    }}>
                                                        Precio de lista:
                                                        <span style={{color: 'lightgray', textDecorationLine:'line-through'}}>
                                                                {formatter.format(currentProducts.reduce((total, curr, i) => {
                                                                    return total + curr.external_price * curr.quantity
                                                                }, 0))}
                                                            {invoice && ' con IVA'}
                                                        </span>
                                                        <br/>

                                                        Precio Hotcher:
                                                        <span style={{color: 'black'}}>
                                                            {formatter.format(totalCart)}
                                                            {invoice && ' con IVA'}
                                                        </span>
                                                        <br/>
                                                        Utilidad:
                                                        <span style={{color: theme1.palette.primary.main}}>
                                                            {formatter.format(currentProducts.reduce((total, curr, i) => {
                                                                return total + curr.external_price * curr.quantity
                                                            }, 0) - totalCart)}
                                                        </span>
                                                        <br/>
                                                        Envio:
                                                        <span style={{color: 'black'}}>
                                                            {formatter.format(totalCart > 500 || currentPackage === 0 ? 0 : 85)}
                                                        </span>

                                                        {
                                                            totalCartDiscount > 0 &&
                                                            <>
                                                                <br/>
                                                                Descuento buen fin:
                                                                <span style={{color: 'red'}}>
                                                                {formatter.format(totalCartDiscount)}
                                                                    {invoice && ' con IVA'}
                                                                </span>
                                                            </>

                                                        }

                                                        <br/>
                                                        Total:
                                                        <span style={{color: theme1.palette.primary.main}}>
                                                            {formatter.format( ((totalCart > 500 || currentPackage === 0 ? totalCart : totalCart + 85) ) - totalCartDiscount )}
                                                        </span>

                                                    </div>
                                                </div>
                                            </div>)}

                                            <div className="form-group row">
                                            <div className="col-xs-12 offset-md-1 col-md-5">
                                                <div>
                                                    <span style={{
                                                        color: theme1.palette.secondary.main,
                                                    }}
                                                    className="mt-3 font-weight-bold font-size-lg">
                                                        Dirección
                                                        <br/>
                                                    </span>
                                                    <span
                                                    className="font-size-lg">
                                                        {currentAddress[0].Address.street + ' num.' + currentAddress[0].Address.num_ext + ' ' + currentAddress[0].Address.num_int}
                                                        <br/>
                                                        {currentAddress[0].Address.colony}
                                                        <br/>
                                                        {currentAddress[0].Address.city + ' ' + currentAddress[0].Address.postal_code}
                                                        <br/>
                                                        {currentAddress[0].Address.state}
                                                        <br/>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-md-5">
                                                <div>
                                                    <span style={{
                                                        color: theme1.palette.secondary.main,
                                                    }}
                                                          className="mt-3 font-weight-bold font-size-lg">
                                                        Detalles de entrega
                                                        <br/>
                                                    </span>
                                                    <span className="font-size-lg">
                                                        Nombre de quien recibe: {currentDetails.name}
                                                        <br/>
                                                        Telefono: {currentDetails.phoneNumber}
                                                        <br/>
                                                        Fecha: {currentDetails.date}
                                                        <br/>
                                                        Forma de pago: {currentDetails.paymentMethod}
                                                        <br/>
                                                        Comentarios: {currentDetails.comments}
                                                        <br/>
                                                    </span>
                                                </div>
                                            </div>
                                            {/*{currentDetails && currentDetails.paymentMethod === 2 && <div style={{width: '100%',margin:'15px'}}>*/}
                                            {/*        <h2 style={{textAlign:"center",margin:'15px'}}>*/}
                                            {/*            Ingresa tus datos de pago*/}
                                            {/*        </h2>*/}
                                            {/*    <div style={{width: '100%'}} className='row'>*/}
                                            {/*        <div className='col-xsl-12 order-1 col-md-6 auxCenter'>*/}
                                            {/*            <Cards*/}
                                            {/*                cvc={credit.cvc}*/}
                                            {/*                expiry={credit.expiry}*/}
                                            {/*                focused={credit.focus}*/}
                                            {/*                name={credit.name}*/}
                                            {/*                number={credit.number}*/}
                                            {/*            />*/}
                                            {/*        </div>*/}
                                            {/*        <div className='col-xsl-12 order-1 col-md-6 '>*/}
                                            {/*            <form action=''>*/}
                                            {/*                <TextField*/}
                                            {/*                    type='number'*/}
                                            {/*                    style={{margin: '10px'}}*/}
                                            {/*                    name='number'*/}
                                            {/*                    value={credit.number}*/}
                                            {/*                    placeholder='Número de tarjeta'*/}
                                            {/*                    onChange={handleInputChange1}*/}
                                            {/*                />*/}
                                            {/*                <br/>*/}
                                            {/*                <TextField*/}
                                            {/*                    type='text'*/}
                                            {/*                    style={{margin: '10px'}}*/}
                                            {/*                    name='name'*/}
                                            {/*                    placeholder='Nombre en la tarjeta'*/}
                                            {/*                    onChange={handleInputChange1}*/}
                                            {/*                />*/}
                                            {/*                <br/>*/}
                                            {/*                <TextField*/}
                                            {/*                    type='number'*/}
                                            {/*                    style={{margin: '10px'}}*/}
                                            {/*                    name='cvc'*/}
                                            {/*                    placeholder='CVC'*/}
                                            {/*                    onChange={handleInputChange1}*/}
                                            {/*                />*/}
                                            {/*                <br/>*/}

                                            {/*                <TextField*/}
                                            {/*                    style={{margin: '10px'}}*/}
                                            {/*                    name='expiry'*/}
                                            {/*                    placeholder='Fecha de cad.'*/}
                                            {/*                    value={credit.expiry}*/}
                                            {/*                    onChange={handleInputChange2}*/}
                                            {/*                />*/}

                                            {/*            </form>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>}*/}
                                        </div></>)
                                    }
                                    <div>
                                        {activeStep === steps.length ? (
                                            <div>
                                                <Typography className={classes.instructions}>
                                                    {saveOrderAux ? 'Tú pedido ha sido guardado puedes continuarlo en las siguiente 72 horas, después será borrado' :  'Enviado, puedes levantar otro pedido si lo deseas'}
                                                </Typography>
                                                <Button onClick={handleReset} className={classes.button}>
                                                    Reiniciar
                                                </Button>
                                            </div>
                                        ) : (
                                            <div style={{float: 'right', margin: '20px 10px', width: '250px'}}>
                                                {!currentError &&<Typography
                                                    className={classes.instructions}>{getStepContent(activeStep)}</Typography>}
                                                {currentError && <Typography
                                                    className={classes.instructions} style={{color: 'red'}}>{currentError}</Typography>}
                                                <div>
                                                    {
                                                        activeStep === steps.length - 1 && currentDetails && currentDetails.paymentMethod === 2 &&
                                                        <PayPalScriptProvider options={{
                                                            components: "buttons",
                                                            "currency" : "MXN",
                                                            "locale": "es_MX",
                                                            "client-id": "AaWmCSKclojcM7hnaZe8HPjoAzB2qGgsgsnet1rE3HI0KrpZzw1WPb2mUVmF_3_YA5dJnog-YzF5LBRf" }}>
                                                            <PayPalButtons
                                                                createOrder={(data, actions) => {
                                                                    setCurrentError(null);
                                                                    return actions.order.create({
                                                                        purchase_units: [
                                                                            {
                                                                                description: "Compra de productos Hotch",
                                                                                currency_code: "MXN",
                                                                                amount: {
                                                                                    value:  ((totalCart > 500 || currentPackage === 0 ? totalCart : totalCart + 85) ) - totalCartDiscount ,
                                                                                },
                                                                            },
                                                                        ],
                                                                    });
                                                                }}
                                                                onApprove={(data, actions) => {
                                                                    return actions.order.capture().then((details) => {
                                                                        const name = details.payer.name.given_name;
                                                                        sendData();
                                                                    });
                                                                }}
                                                                onError={()=>{
                                                                    return setCurrentError(`Error al procesar la compra`);
                                                                }}
                                                                onCancel={()=>{
                                                                    return setCurrentError('Proceso de pago cancelado');
                                                                }}
                                                                style={{ layout: "horizontal" }} />
                                                        </PayPalScriptProvider>
                                                    }
                                                    <Button disabled={activeStep === 0} onClick={handleBack}
                                                            className={classes.button}>
                                                        Atras
                                                    </Button>
                                                    <Button
                                                        disabled={activeStep !== 3}
                                                        onClick={saveData}
                                                        className={classes.button}>
                                                        Guardar
                                                    </Button>

                                                    {activeStep < steps.length - 1 && <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleNext}
                                                            className={classes.button}
                                                        >
                                                            Siguiente
                                                        </Button>}
                                                    {activeStep === steps.length - 1 && currentDetails && currentDetails.paymentMethod !== 2 && <Button
                                                        color="primary"
                                                        onClick={() => {
                                                            if(currentDetails.paymentMethod === 3){
                                                                sendData()
                                                            } else {
                                                                sendData()
                                                            }
                                                        }}
                                                    >{currentDetails.paymentMethod === 3 ? 'Finalizar' : 'Finalizar'}</Button>}


                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>


                </div>
            </CardBody>
        </Card></>
    );
}
