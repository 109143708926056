import React, {useEffect, useMemo, useState} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { ProductsFilter } from "./products-filter/ProductsFilter";
import { ProductsTable } from "./products-table/ProductsTable";
import { ProductsGrouping } from "./products-grouping/ProductsGrouping";
import { useProductsUIContext } from "./ProductsUIContext";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../_redux/products/productsActions";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';


export function ProductsCard() {
    const productsUIContext = useProductsUIContext();
    const dataAuth = useSelector(state => state.auth);
    const [loadedMessage, setLoadedMessage] = useState("");
    const [data, setData] = useState(null);


    useEffect(()=>{

        // dataAuth.authToken;
        const updateForm = document.getElementById('updateForm')
        updateForm && updateForm.addEventListener('submit', (e) => {
            setData(null)
            e.preventDefault()
            const formData = new FormData(updateForm)
            fetch('https://hbplat.serv-lab.com/api/private/products/multiupdate', {
                method: 'POST',
                body: formData,
                headers: new Headers({
                    'Authorization': 'Bearer '+ dataAuth.authToken,
                }),
            }).then((res)=>{
                res.json().then((x)=>{
                    if(x.changes){
                        setLoadedMessage("Presupuesto subido correctamente, este es el resumen")
                        setData(x.changes)
                    }else{
                        setLoadedMessage("Algo salio mal")
                    }
                })
            })
                .then((data)=>{

                    console.log(data)
                })
        })
    },[dataAuth.authToken])

    const productsUIProps = useMemo(() => {
        return {
            ids: productsUIContext.ids,
            queryParams: productsUIContext.queryParams,
            setQueryParams: productsUIContext.setQueryParams,
            newProductButtonClick: productsUIContext.newProductButtonClick,
            openDeleteProductsDialog: productsUIContext.openDeleteProductsDialog,
            openEditProductPage: productsUIContext.openEditProductPage,
            openUpdateProductsStatusDialog:
            productsUIContext.openUpdateProductsStatusDialog,
            openFetchProductsDialog: productsUIContext.openFetchProductsDialog,
        };
    }, [productsUIContext]);
    const dispatch = useDispatch();
    const getCSV = () =>{
        dispatch(actions.fetchProductsAdminCSV());
    }

    return (
        <Card>
            <CardHeader title="Lista de productos">
                <CardHeaderToolbar>
                    <Dialog
                        open={loadedMessage}
                        onClose={()=> setLoadedMessage("")}
                        aria-labelledby="alert-dialog-title"
                        maxWidth={"lg"}
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Importe de datos via excel, RESUMEN"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText align="center" a id="alert-dialog-description">
                                {loadedMessage}
                                <br/>
                                <br/>
                                {data &&
                                    <>
                                        <span  style={{fontWeight:"bold"}}>Productos creados:</span>
                                        <span style={{color:"red"}}>{data.created}</span>
                                        <br/>
                                    </>
                                }
                                {data &&
                                    <>
                                        <span  style={{fontWeight:"bold"}}>Productos actualizados:</span>
                                        <span style={{color:"red"}}>{data.updated}</span>
                                        <br/>
                                    </>
                                }
                                {data &&
                                    <>
                                        <span style={{fontWeight:"bold"}}>Productos invalidos:</span>
                                        <span style={{color:"red"}}>{data.invalidId}</span>
                                        <br/>
                                    </>
                                }
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=> setLoadedMessage("")} color="primary" autoFocus>
                                Okay
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {dataAuth.user.admin_user_id && <div>

                        <button
                            type="button"
                            style={{marginRight:'15px'}}
                            className="btn btn-primary"
                            onClick={getCSV}
                        >
                            Descargar Producto CSV
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={productsUIProps.newProductButtonClick}
                        >
                            Producto nuevo
                        </button>
                        <form id="updateForm" style={{margin:'10px 0'}} method="post" action="https://hbplat.serv-lab.com/api/private/products/multiupdate">
                            <input type="file" id="myFile" name="batch" />
                            <input type="submit"  className="btn btn-primary" />
                        </form>
                    </div>}
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                {/*<ProductsFilter />*/}
                {/*{productsUIProps.ids.length > 0 && (*/}
                {/*  <>*/}
                {/*    <ProductsGrouping />*/}
                {/*  </>*/}
                {/*)}*/}
                <ProductsTable />
            </CardBody>
        </Card>
    );
}

