/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import {useSelector} from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };
  const dataAuth = useSelector(state => state.auth);

  console.log(dataAuth);

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Panel Principal</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}


          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/sales/customers", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/sales/customers">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
            </span>
              <span className="menu-text">Clientes</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/sales/products", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/sales/products">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart1.svg")}/>
            </span>
              <span className="menu-text">Productos</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/sales/orders", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/sales/orders">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Ticket.svg")}/>
            </span>
              <span className="menu-text">Ordenes</span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/sales/ordersSave", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/sales/ordersSave">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Save.svg")}/>
            </span>
              <span className="menu-text">Ordenes Guardadas</span>
            </NavLink>
          </li>
          {!dataAuth.user.admin_user_id && <li
              className={`menu-item ${getMenuItemActive("/mi-equipo", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/mi-equipo">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Dial-numbers.svg")}/>
            </span>
              <span className="menu-text">Mi Equipo</span>
            </NavLink>
          </li>}
          {/*{!dataAuth.user.admin_user_id && <li*/}
          {/*    className={`menu-item ${getMenuItemActive("/team/membership", false)}`}*/}
          {/*    aria-haspopup="true"*/}
          {/*>*/}
          {/*  <NavLink className="menu-link" to="/team/membership">*/}
          {/*  <span className="svg-icon menu-icon">*/}
          {/*    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}/>*/}
          {/*  </span>*/}
          {/*    <span className="menu-text">Membresia</span>*/}
          {/*  </NavLink>*/}
          {/*</li>}*/}
          {!dataAuth.user.admin_user_id && <li
              className={`menu-item ${getMenuItemActive("/team/add", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/team/add">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Book.svg")}/>
            </span>
              <span className="menu-text">Invitar a un amigo</span>
            </NavLink>
          </li>}
          {
          //   !dataAuth.user.admin_user_id && <li
          //     className={`menu-item ${getMenuItemActive("/team/members1", false)}`}
          //     aria-haspopup="true"
          //     >
          //   <NavLink className="menu-link" to="/team/members1">
          //   <span className="svg-icon menu-icon">
          //     <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Wood1.svg")}/>
          //   </span>
          //     <span className="menu-text">Invitar a alguien</span>
          //   </NavLink>
          // </li>
          }
          {/*end::1 Level*/}
          {/*begin::1 Level*/}
          {dataAuth.user.admin_user_id && <li
              className={`menu-item ${getMenuItemActive("/sales/users", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/sales/users">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Adress-book2.svg")}/>
            </span>
              <span className="menu-text">Usuarios</span>
            </NavLink>
          </li>}
          {/*{dataAuth.user.admin_user_id && <li*/}
          {/*    className={`menu-item ${getMenuItemActive("/reportDiscounts", false)}`}*/}
          {/*    aria-haspopup="true"*/}
          {/*>*/}
          {/*  <NavLink className="menu-link" to="/reportDiscounts">*/}
          {/*  <span className="svg-icon menu-icon">*/}
          {/*    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Archive.svg")}/>*/}
          {/*  </span>*/}
          {/*    <span className="menu-text">Reporte descuentos</span>*/}
          {/*  </NavLink>*/}
          {/*</li>}*/}
          {/*{dataAuth.user.admin_user_id && <li*/}
          {/*    className={`menu-item ${getMenuItemActive("/reportTeams", false)}`}*/}
          {/*    aria-haspopup="true"*/}
          {/*>*/}
          {/*  <NavLink className="menu-link" to="/reportTeams">*/}
          {/*  <span className="svg-icon menu-icon">*/}
          {/*    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Dial-numbers.svg")}/>*/}
          {/*  </span>*/}
          {/*    <span className="menu-text">Reporte equipos</span>*/}
          {/*  </NavLink>*/}
          {/*</li>}*/}
          {/*end::1 Level*/}
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
