import axios from "axios";

export const CUSTOMERS_URL = "https://hbplat.serv-lab.com/api/private/orders";

// CREATE =>  POST: add a new order to the server
export function createOrder(order) {
  if(order.edit){
    return axios.post(CUSTOMERS_URL + '/updateC',  order );
  }else{
    return axios.post(CUSTOMERS_URL + '/create',  order );
  }
}

// READ
export function getAllOrders() {
  return axios.get(`${CUSTOMERS_URL}/getAll`);
}

export function getOrderById(orderId) {
  return axios.get(`${CUSTOMERS_URL}/${orderId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findOrders(queryParams) {
  return axios.post(`${CUSTOMERS_URL}/getAllByUser`,  queryParams );
}
// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findOrdersSaved(queryParams) {
  return axios.post(`${CUSTOMERS_URL}/getAllByUserSaved`,  queryParams );
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findOrdersAdmin(queryParams) {
  return axios.get(`${CUSTOMERS_URL}/getAll`, { queryParams });
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findOrdersAdminSaved(queryParams) {
  return axios.get(`${CUSTOMERS_URL}/getAllSaved`, { queryParams });
}

// UPDATE => PUT: update the order on the server
export function updateOrder(order) {
  return axios.put(`${CUSTOMERS_URL}/${order.id}`, { order });
}

// READ
export function getAllOrdersCSV() {
  return axios.get(`${CUSTOMERS_URL}/getAllCSV`);
}

// READ
export function getAllOrdersCSVById(id) {
  return axios.get(`${CUSTOMERS_URL}/getAllCSV/user/${id}`);
}


// READ
export function getReceipt(id) {
  return axios.get(`https://hbplat.serv-lab.com/api/private/downloadReceipt/${id}`);
}

// UPDATE Status
export function updateStatusForOrders(status) {
  return axios.post(`${CUSTOMERS_URL}/updateStatus`,
    status
  );
}

// DELETE => delete the order from the server
export function deleteOrder(orderId) {
  return axios.delete(`${CUSTOMERS_URL}/delete/${orderId}`);
}

// DELETE Orders by ids
export function deleteOrders(ids) {
  return axios.post(`${CUSTOMERS_URL}/deleteOrders`, { ids });
}
