import React from 'react';
import {toAbsoluteUrl} from '../../../../_metronic/_helpers';
import SVG from 'react-inlinesvg';

import './ProgressBar.scss'

export function ProgressBarDiv(props){

  //console.log("Bootle");
  //console.log(props.total);
  //console.log(props.bono);
  //console.log(props.half);
  //console.log(props.desc);

  //Total de Bono: ${props.bono}

  let color00 = '#DDFFF7CC'
  let color01 = '#93E1D8CC'
  let color02 = '#4F7CACCC'
  let color03 = '#AA4465CC'
  let color04 = '#462255CC'
  let color05 = '#573664CC'
  let color06 = ''
  let color07 = ''

  return (
    <div className="bar-component">
      <h6> {props.name} </h6>
      <div class="w3-border bar">
        <div className="subBar" style = {{ 'width':props.relativePercentageB1+'%' , 'background-color': color00 }}/>
        <div className="subBar" style = {{ 'width':props.relativePercentageB2+'%', 'background-color': color01 }}/>
        <div className="subBar" style = {{ 'width':props.relativePercentageB3+'%', 'background-color': color02 }}/>
        <div className="subBar" style = {{ 'width':props.relativePercentageB4+'%', 'background-color': color03 }}/>
      </div>
      <p> {props.desc} </p>
    </div>


  );
}
