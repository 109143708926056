import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/users/usersActions";
import { CustomerEditDialogHeader } from "./CustomerEditDialogHeader";
import { CustomerEditForm } from "./CustomerEditForm";
import { useCustomersUIContext } from "../CustomersUIContext";
import * as actions1 from "../../../_redux/users/usersActions";

export function CustomerEditDialog({ id, show, onHide, user_id }) {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  console.log(user_id)
  const customersUIProps = useMemo(() => {
    return {
      initCustomer: {
        street: "",
        state: "",
        city: "",
        postal_code: "",
        colony: "",
        num_int: "",
        num_ext: "",
        user_id: user_id,
      },
    };
  }, [customersUIContext]);

  // Customers Redux state
  const dispatch = useDispatch();
  const { actionsLoading, customerForEdit } = useSelector(
      (state) => ({
        actionsLoading: state.customers.actionsLoading,
        customerForEdit: state.customers.customerForEdit,
      }),
      shallowEqual
  );

  // server request for saving customer
  const saveOrder = (customer) => {
    let auxObj = {...customer};
    auxObj.user_id = user_id;
    dispatch(actions.createAddress(customer)).then(() => {dispatch(actions1.fetchUserAddress({user_id})); onHide()});
  };

  return (
      <Modal
          size="lg"
          show={show}
          onHide={onHide}
          aria-labelledby="example-modal-sizes-title-lg"
      >
        <CustomerEditDialogHeader id={id} />
        <CustomerEditForm
            user_id={user_id}
            saveOrder={saveOrder}
            actionsLoading={actionsLoading}
            customer={customerForEdit || customersUIProps.initCustomer}
            onHide={onHide}
        />
      </Modal>
  );
}
