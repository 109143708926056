// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import {Modal} from "react-bootstrap";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {
    Input,
    Select,
    DatePickerField,
} from "../../../../../../_metronic/_partials/controls";

// Validation schema
const CustomerEditSchema = Yup.object().shape({
    street: Yup.mixed()
        .nullable(true),
    state: Yup.mixed()
        .nullable(true),
    city: Yup.mixed()
        .nullable(true),
    colony: Yup.mixed()
        .nullable(true),
    postal_code: Yup.mixed()
        .nullable(true),
    num_int: Yup.mixed()
        .nullable(true),
    num_ext: Yup.mixed()
        .nullable(false),
    user_id: Yup.mixed()
        .nullable(false),
});

export function CustomerEditForm({
                                     saveOrder,
                                     customer,
                                     user_id,
                                     actionsLoading,
                                     onHide,
                                 }) {
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={customer}
                validationSchema={CustomerEditSchema}
                onSubmit={(values) => {
                    let auxObj = {...values};
                    auxObj.user_id = user_id;
                    saveOrder(auxObj);
                }}
            >
                {({handleSubmit}) => (
                    <>
                        <Modal.Body className="overlay overlay-block">
                            {/*<Modal.Body className="overlay overlay-block">*/}
                            {/*{actionsLoading && (*/}
                            {/*    <div className="overlay-layer bg-transparent">*/}
                            {/*        <div className="spinner spinner-lg spinner-success"/>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            <Form className="form form-label-right">
                                {/* Email */}
                                <div className="form-group row">
                                    {/* Last Name */}
                                    <div className="col-lg-4">
                                        <Field
                                            name="state"
                                            component={Input}
                                            placeholder="Estado"
                                            label="Estado"
                                        />
                                    </div>
                                    {/* First Name */}
                                    <div className="col-lg-4">
                                        <Field
                                            name="city"
                                            component={Input}
                                            placeholder="Municipio/alcaldia"
                                            label="Municipio/alcaldia"
                                        />
                                    </div>
                                    {/* Last Name */}
                                    <div className="col-lg-4">
                                        <Field
                                            name="postal_code"
                                            component={Input}
                                            placeholder="Codigo Postal"
                                            label="Codigo Postal"
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    {/* First Name */}
                                    <div className="col-lg-4">
                                        <Field
                                            name="colony"
                                            component={Input}
                                            placeholder="Colonia"
                                            label="Colonia"
                                        />
                                    </div>
                                    {/* Last Name */}
                                    <div className="col-lg-4">
                                        <Field
                                            name="num_int"
                                            component={Input}
                                            placeholder="Numero interno"
                                            label="Numero interno"
                                        />
                                    </div>
                                    {/* Last Name */}
                                    <div className="col-lg-4">
                                        <Field
                                            name="num_ext"
                                            component={Input}
                                            placeholder="Numero externo"
                                            label="Numero externo"
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    {/* IP Address */}
                                    <div className="col-lg-4">
                                        <Field
                                            name="street"
                                            component={Input}
                                            placeholder="Calle"
                                            label="Calle"
                                            customFeedbackLabel="La información de todos los usuarios es privada."
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <input
                                            name="user_id"
                                            style={{display:'none'}}
                                            value={user_id}
                                        />
                                    </div>
                                </div>

                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                onClick={onHide}
                                className="btn btn-light btn-elevate"
                            >
                                Cancelar
                            </button>
                            <> </>
                            <button
                                type="submit"
                                onClick={() => {
                                    console.log(user_id)
                                    handleSubmit();
                                }}
                                className="btn btn-primary btn-elevate"
                            >
                                Guardar
                            </button>
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </>
    );
}

