import React from "react";
import {Route} from "react-router-dom";
import {ProductsLoadingDialog} from "./products-loading-dialog/ProductsLoadingDialog";
import {ProductDeleteDialog} from "./product-delete-dialog/ProductDeleteDialog";
import {ProductEdit} from "./product-edit/ProductEdit";
import {ProductsDeleteDialog} from "./products-delete-dialog/ProductsDeleteDialog";
import {ProductsFetchDialog} from "./products-fetch-dialog/ProductsFetchDialog";
import {ProductsUpdateStatusDialog} from "./products-update-status-dialog/ProductsUpdateStatusDialog";
import {ProductsCard} from "./ProductsCard";
import {ProductsUIProvider} from "./ProductsUIContext";
import {CustomerEditDialog} from "../customers/customer-edit-dialog/CustomerEditDialog";

export function ProductsPage(aux) {
    console.log(aux)
    const { history } = aux;
    const productsUIEvents = {
        newProductButtonClick: () => {
            history.push("/sales/products/new");
        },
        openEditProductPage: (id) => {
            history.push(`/sales/products/${id}/edit`);
        },
        openDeleteProductDialog: (id) => {
            history.push(`/sales/products/${id}/delete`);
        },
        openDeleteProductsDialog: () => {
            history.push(`/sales/products/deleteProducts`);
        },
        openFetchProductsDialog: () => {
            history.push(`/sales/products/fetch`);
        },
        openUpdateProductsStatusDialog: () => {
            history.push("/sales/products/updateStatus");
        },
    };

    return (
        <ProductsUIProvider productsUIEvents={productsUIEvents}>
            <ProductsCard/>

            <ProductsLoadingDialog/>
            <Route path="/sales/products/new">
                {({history, match}) => {
                    console.log(match);
                    if(match !== null){
                        return (<ProductEdit
                                show={match !== undefined}
                                onHide={() => {
                                    history.push("/sales/products");
                                }}
                            />
                        )
                    }
                }}
            </Route>
            <Route path="/sales/products/deleteProducts">
                {({history, match}) => (
                    <ProductsDeleteDialog
                        show={match != null}
                        onHide={() => {
                            history.push("/sales/products");
                        }}
                    />
                )}
            </Route>
            <Route path="/sales/products/:id/delete">
                {({history, match}) => (
                    <ProductDeleteDialog
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push("/sales/products");
                        }}
                    />
                )}
            </Route>
            <Route path="/sales/products/fetch">
                {({history, match}) => (
                    <ProductsFetchDialog
                        show={match != null}
                        onHide={() => {
                            history.push("/sales/products");
                        }}
                    />
                )}
            </Route>
            <Route path="/sales/products/updateStatus">
                {({history, match}) => (
                    <ProductsUpdateStatusDialog
                        show={match != null}
                        onHide={() => {
                            history.push("/sales/products");
                        }}
                    />
                )}
            </Route>
        </ProductsUIProvider>
    );
}
