/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler, DropdownMenu3 } from "../../dropdowns";

export function ListsWidget10({ className, data }) {
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Ganancias
          </h3>


        </div>

        {/* Body */}
        <div className="card-body pt-0">
          <div className="mb-6">
            <div className="d-flex align-items-center flex-grow-1">
              {/*<label className="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">*/}
              {/*  <input type="checkbox" value="1" />*/}
              {/*  <span></span>*/}
              {/*</label>*/}

              <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                <div className="d-flex flex-column align-items-cente py-2 w-75">
                  <a
                    href="#"
                    className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                  >
                    Total bono por invitar personas recibido:
                  </a>

                  <span className="text-muted font-weight-bold">
                    Fecha: 02/04/21
                  </span>
                </div>

                <span className="label label-lg label-light-primary label-inline font-weight-bold py-4">
                  ${data && data.length * 200}
                </span>
              </div>
            </div>
          </div>

          <div className="mb-6">
            <div className="d-flex align-items-center flex-grow-1">
              {/*<label className="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">*/}
              {/*  <input type="checkbox" value="1" />*/}
              {/*  <span></span>*/}
              {/*</label>*/}

              {/* begin::Section */}
              <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                {/* begin::Info */}
                <div className="d-flex flex-column align-items-cente py-2 w-75">
                  {/* begin::Title */}
                  <a
                    href="#"
                    className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                  >
                    Total de usuarios invitados
                  </a>
                  {/* end::Title */}

                  {/* begin::Data */}
                  <span className="text-muted font-weight-bold">
                    Fecha: 01/04/21
                  </span>
                  {/* end::Data */}
                </div>
                {/* end::Info */}

                {/* begin::Label */}
                <span className="label label-lg label-light-success label-inline font-weight-bold py-4">
                  {data && data.length}
                </span>
                {/* end::Label */}
              </div>
              {/* end::Section */}
            </div>
            {/* end::Content */}
          </div>
          {/* end::Item */}

          {/* begin::Item */}
          {/*<div className="mb-6">*/}
          {/*  /!* begin::Content *!/*/}
          {/*  <div className="d-flex align-items-center flex-grow-1">*/}
          {/*    /!* begin::Checkbox *!/*/}
          {/*    <label className="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">*/}
          {/*      <input type="checkbox" value="1" />*/}
          {/*      <span></span>*/}
          {/*    </label>*/}
          {/*    /!* end::Checkbox *!/*/}

          {/*    /!* begin::Section *!/*/}
          {/*    <div className="d-flex flex-wrap align-items-center justify-content-between w-100">*/}
          {/*      /!* begin::Info *!/*/}
          {/*      <div className="d-flex flex-column align-items-cente py-2 w-75">*/}
          {/*        /!* begin::Title *!/*/}
          {/*        <a*/}
          {/*          href="#"*/}
          {/*          className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"*/}
          {/*        >*/}
          {/*          Venta de $950.00 por Francisco*/}
          {/*        </a>*/}
          {/*        /!* end::Title *!/*/}

          {/*        /!* begin::Data *!/*/}
          {/*        <span className="text-muted font-weight-bold">*/}
          {/*          Fecha: 02/04/21*/}
          {/*        </span>*/}
          {/*        /!* end::Data *!/*/}
          {/*      </div>*/}
          {/*      /!* end::Info *!/*/}

          {/*      /!* begin::Label *!/*/}
          {/*      <span className="label label-lg label-light-success label-inline font-weight-bold py-4">*/}
          {/*        Entragada*/}
          {/*      </span>*/}
          {/*      /!* end::Label *!/*/}
          {/*    </div>*/}
          {/*    /!* end::Section *!/*/}
          {/*  </div>*/}
          {/*  /!* end::Content *!/*/}
          {/*</div>*/}
          {/* end::Item */}

          {/* begin::Item */}

          {/* end: Item */}

          {/* begin: Item */}

          {/* end: Item */}
        </div>
      </div>
    </>
  );
}
