// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/orders/ordersActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../CustomersUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useCustomersUIContext } from "../CustomersUIContext";
import {Empty} from 'antd';

export function CustomersTable() {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const dataAuth = useSelector(state => state.auth);

  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
      openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog,
    };
  }, [customersUIContext]);

  // Getting curret state of customers list from store (Redux)
  const { currentState } = useSelector(
      (state) => ({ currentState: state.orders }),
      shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  const auxLocation = window.location.href.includes("Save");
  console.log(auxLocation)
  // Customers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    customersUIProps.setIds([]);
    // server call by queryParams
    if(dataAuth.user.admin_user_id){
      if(auxLocation){
        dispatch(actions.fetchOrdersAdminSaved(customersUIProps.queryParams));
      }else{
        dispatch(actions.fetchOrdersAdmin(customersUIProps.queryParams));
      }
    } else {
      if(auxLocation){
        dispatch(actions.fetchOrdersSaved({user_id:dataAuth.user.user_id}));

      }else{
        dispatch(actions.fetchOrders({user_id:dataAuth.user.user_id}));
      }
    }   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersUIProps.queryParams, dispatch]);
  // Table columns
  let auxObj = {}

  const mapEnumPaymentMethod = (paymentMethod) => {
    const map = {
      1: '1 - Efectivo',
      2: '2 - Tarjeta',
      3: '3 - Transferencia'
    };

    return map[paymentMethod];
  };

  const mapEnumStatus = (status) => {
    const map = {
      1: '1 - Pedido',
      2: '2 - Preparado',
      3: '3 - Cargado',
      4: '4 - Entregado'
    };

    return map[status];
  };

  const mapEnumStatusPayment = (statusPayment) => {
    const map = {
      1: '1 - Pendiente',
      2: '2 - Pagado'
    };

    return map[statusPayment];
  };
  console.log(customersUIProps)

  if(dataAuth.user.admin_user_id){
    auxObj = {
      download: (id) => dispatch(actions.fetchOrdersAdminCSVById(id)),
      openDeleteCustomerDialog: customersUIProps.openDeleteCustomerDialog,
    }
  }else{
    auxObj =  {
      openEditCustomerDialog: customersUIProps.openEditCustomerDialog,
      openDeleteCustomerDialog: customersUIProps.openDeleteCustomerDialog,
    }
  }

   const statusFormat = (cellContent, row) => <>{mapEnumStatus(cellContent)}</>;


   const paymentFormat = (cellContent, row) => <>{mapEnumPaymentMethod(cellContent)}</>;

  const columns = [
    {
      dataField: "order_id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "Shipment.date",
      text: "Fecha entrega",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "createdAt",
      text: "Fecha pedido",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "Address.city",
      text: "Delegación entrega",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "User.name",
      text: "Nombre vendedor",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "payment_method",
      text: "Metodo de pago",
      sort: true,
      sortCaret: sortCaret,
      formatter: paymentFormat,
    },
    {
      dataField: "status",
      text: "Estatus",
      sort: true,
      sortCaret: sortCaret,
      formatter: statusFormat,
    },
    {
      dataField: "total",
      text: "Total",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.PriceColumnFormatter,
    },
    {
      dataField: "action",
      text: "Acciones",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: auxObj,
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber,
  };
  return (
      <>
        {entities && entities.length === 0 &&
        <Empty description="Sin datos disponibles por el momento o ocurrio un error en la conexión"/>}
        {entities && entities.length > 0 && <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({paginationProps, paginationTableProps}) => {
            return (
                // <Pagination
                //     isLoading={listLoading}
                //     paginationProps={paginationProps}
                // >
                <BootstrapTable
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center"
                    bootstrap4
                    remote
                    keyField="id"
                    data={entities === null ? [] : entities}
                    columns={columns}
                    defaultSorted={uiHelpers.defaultSorted}
                    onTableChange={getHandlerTableChange(
                        customersUIProps.setQueryParams
                    )}
                    // selectRow={getSelectRow({
                    //   entities,
                    //   ids: customersUIProps.ids,
                    //   setIds: customersUIProps.setIds,
                    // })}
                    {...paginationTableProps}
                >
                  <PleaseWaitMessage entities={entities}/>
                  <NoRecordsFoundMessage entities={entities}/>
                </BootstrapTable>
                // </Pagination>
            );
          }}
        </PaginationProvider>}
      </>
  );
}
