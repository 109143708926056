/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useState} from "react";
import {Nav, Tab} from "react-bootstrap";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_helpers";
import {shallowEqual, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import { Empty } from 'antd';

export function AdvanceTablesWidgetTeam({className}) {
    const [key, setKey] = useState("Month");
    const dataAuth = useSelector(state => state.auth);
    // Getting curret state of customers list from store (Redux)
    const {currentState} = useSelector(
        (state) => ({currentState: state.orders}),
        shallowEqual
    );

    console.log(currentState)

    var formatter = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
    });

    const getTotalProduct = (input) => {
        return input.Orders_Products_Relationships.reduce((total,curr)=>{
           return total + curr.quantity
        },0);
    }

    const getTotalDiscount = (order) => {
        console.log(order)
        return order.Orders_Products_Relationships.reduce((total,curr)=>{
            let auxValue = order.invoice ? 1.16 : 1
            return total + (auxValue * curr.Product.external_price * curr.quantity)
        },0);
    }
    const mapEnumStatus = (status) => {
        const map = {
            1: '1 - Pedido',
            2: '2 - Preparado',
            3: '3 - Cargado',
            4: '4 - Entregado'
        };

        return map[status];
    };

    return (
        <div className={`card card-custom ${className}`}>
            {/* Head */}
            <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Ordenes
          </span>
                    <span className="text-muted mt-3 font-weight-bold font-size-sm">
            Ultimas ordenes realizadas
          </span>
                </h3>
            </div>
            {/* Body */}
            {currentState.entities && currentState.entities.length === 0 && <Empty description="Sin datos disponibles por el momento o ocurrio un error en la conexión" />}
            {currentState.entities && currentState.entities.length > 0 && (<div className="card-body pt-3 pb-0">
                <div className="table-responsive">
                    <table className="table table-borderless table-vertical-center">
                        <thead>
                        <tr>
                            <th className="p-0" style={{width: '50px'}}/>
                            <th className="p-0" style={{minWidth: '180px'}}/>
                            <th className="p-0" style={{minWidth: '75px'}}/>
                            <th className="p-0" style={{minWidth: '75px'}}/>
                            <th className="p-0" style={{minWidth: '75px'}}/>
                            <th className="p-0" style={{minWidth: '25px'}}/>
                            <th className="p-0" style={{minWidth: '125px'}}/>
                            <th className="p-0" style={{minWidth: '65px'}}/>
                            <th className="p-0" style={{minWidth: '65px'}}/>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="pl-0 py-4">

                            </td>
                            <td className="pl-0">
                                Nombre y correo
                            </td>
                            <td className="text-right">
                                Total pagado
                            </td>
                            <td className="text-right">
                                Total para venta
                                {/*<span className="text-muted font-weight-bold">Pagado</span>*/}
                            </td>
                            <td className="text-right">
                                Utilidad
                                {/*<span className="text-muted font-weight-bold">Pagado</span>*/}
                            </td>
                            <td className="text-right">
                                Unidades
                            </td>
                            <td className="text-right">
                                Estatus
                            </td>
                            <td className="text-right">
                                IVA
                            </td>
                            <td className="text-right pr-0">
                                Ver
                            </td>
                        </tr>
                        {currentState.entities && (
                            currentState.entities.map((order, i) => {
                                if (i < 5) return (<tr>
                                    <td className="pl-0 py-4">
                                        <div className="symbol symbol-50 symbol-light mr-1">
                                  <span className="symbol-label">
                                    <SVG
                                        className="h-75 align-self-end"
                                        src={toAbsoluteUrl('/media/svg/avatars/009-boy-4.svg')}
                                    />
                                  </span>
                                        </div>
                                    </td>
                                    <td className="pl-0">
                                        <a
                                            href="#"
                                            className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                        >
                                            {order.User.name + ' ' + order.User.last_name_1}
                                        </a>
                                        <div>
                                            <span className="font-weight-bolder">Email:</span>{' '}
                                            <a
                                                className="text-muted font-weight-bold text-hover-primary"
                                                href="#"
                                            >
                                                {order.User.email}
                                            </a>
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                          {formatter.format(order.total)}
                                        </span>
                                        {/*<span className="text-muted font-weight-bold">Pagado</span>*/}
                                    </td>
                                    <td className="text-right">
                                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                          {order && formatter.format(getTotalDiscount(order))}
                                        </span>
                                        {/*<span className="text-muted font-weight-bold">Pagado</span>*/}
                                    </td>

                                    <td className="text-right">
                                        <span className="text-primary font-weight-bolder d-block font-size-lg">
                                          {order && formatter.format(getTotalDiscount(order) - order.total)}
                                        </span>
                                    </td>
                                    <td className="text-right">
                                        <span className="text-muted font-weight-500">
                                          {order && getTotalProduct(order)}
                                        </span>
                                    </td>
                                    <td className="text-right">
                                        <span className="label label-lg label-light-primary label-inline">
                                            {mapEnumStatus(order.status)}
                                        </span>
                                    </td>
                                    <td className="text-right">
                                        {
                                            order.invoice ?
                                                (<span className="label label-lg label-light-danger label-inline">
                                                    Con
                                                </span>)
                                                :
                                                (<span className="label label-lg label-light-primary label-inline">
                                                    Sin
                                                </span>)
                                        }

                                    </td>
                                    <td className="text-right pr-0">
                                        <NavLink to={'/sales/orders/' + order.order_id + '/view'}
                                                 className="btn btn-icon btn-light btn-sm">
                                          <span className="svg-icon svg-icon-md svg-icon-primary">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    '/media/svg/icons/General/Visible.svg'
                                                )}
                                            />
                                          </span>
                                        </NavLink>
                                    </td>
                                </tr>)
                            })
                        )}
                        </tbody>
                    </table>
                </div>
            </div>)}
        </div>
    );
}
