/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler, DropdownMenu1 } from "../../dropdowns";
import { toAbsoluteUrl } from "../../../_helpers";
import {NavLink} from "react-router-dom";
import {useDispatch} from "react-redux";
import * as actionsUser from "../../../../app/modules/ECommerce/_redux/users/usersActions";
import {setUserToComplete} from "../../../../app/modules/ECommerce/_redux/users/usersActions";

export function ListsWidget14({ className, data }) {
  const dispatch = useDispatch();

  const uploadClick = (id) =>{
     dispatch(actionsUser.setUserToComplete(id));
  };

  return (
    <>
      {/* begin::List Widget 14 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Equipo hotch
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-2">
          {/* begin::Item */}
          <div className="d-flex flex-wrap align-items-center mb-10">

            {/* end::Symbol */}

            {/* begin::Title */}
            {data && data.map((element)=>{
              return (
                  <div style={{ width: '100%' }} className="d-flex flex-wrap align-items-center mb-10">
                    {/* begin::Symbol */}

                    {/* end::Symbol */}

                    {/* begin::Title */}
                    <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                      <span
                        className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                        >
                          {element.user.name}
                    </span>
                    <span className="text-muted font-weight-bold font-size-sm my-1">
                {element.user.Address.city + ' ' + element.user.Address.colony}
              </span>
                    <span className="text-muted font-weight-bold font-size-sm">
                        <span className="text-primary font-weight-bold">{ element.user.email }</span>
                    </span>
                    <span className="text-muted font-weight-bold font-size-sm">
                        Miembro desde:{" "}
                        <span className="text-primary font-weight-bold">{ element.user.createdAt }</span>
                    </span>
                    </div>
                    {/* end::Title */}

                    {/* begin::Info */}
                      <div  className="d-flex align-items-center py-lg-0 py-2">
                          <div className="d-flex flex-column text-right">
                            {/*<span className="text-dark-75 font-weight-bolder font-size-h4">*/}
                            {/*  $24,900.00*/}
                            {/*</span>*/}
                            {/*<span className="text-muted font-size-sm font-weight-bolder">*/}
                            {/*   Venta mes*/}
                            {/*</span>*/}


                          </div>
                      </div>
                    {/* end::Info */}
                  </div>
              )
            })

            }
            {/* end::Title */}
          </div>
          {/* end: Item */}

          {/* begin::Item */}

          {/* end::Item */}

          {/* begin::Item */}

          {/* end::Item */}

          {/* begin::Item */}

          {/* end::Item */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::List Widget 14 */}
    </>
  );
}
