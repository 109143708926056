import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import * as actionsUser from '../ECommerce/_redux/users/usersActions';

import './PerfilUsuario.scss'
import {ListsWidget14} from "../../../_metronic/_partials/widgets";
import {makeStyles, TextField} from "@material-ui/core";
import {Modal, Select} from "antd";
import {Option} from "antd/es/mentions";

const useStyles2 = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));


export function PerfilUsuario() {

  const dispatch = useDispatch();
  const userAuth = useSelector(state => state.auth);
  const userData = userAuth.user;
  const currentListAddress = useSelector(
      (state) => (state.users.addressOrder),
      shallowEqual
  );
  const userType = userAuth.user.type_user_id;
  const user = useSelector(state => state.users);
  const dataUsers = useSelector(state => state.users);

  const userReport = user.report;
  // const userData = userReport.user;


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [firstOpen, setFirstOpen] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const classes2 = useStyles2();


  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  let currentMonthTotal = 0;

  if(userReport != null){
    currentMonthTotal = userReport.currentMonthTotal;
    console.log("total de ventas del mes: " + currentMonthTotal);
  }

  const [values, setValues] = React.useState({
    name:"",
    last_name_1:"",
    last_name_2:"",
    email:"",
    genre:"",
    phone:"",
    type_user_id:"",
    type:"",
    dateOfBirth:"",
    birthday:"",
    street:"",
    state:"",
    city:"",
    postal_code:"",
    colony:"",
    num_int:"",
    num_ext:"",
    address_id:"",
    user_id:""
  });

  const [address, setAddress] = React.useState([{
    street:"",
    state:"",
    city:"",
    postal_code:"",
    colony:"",
    num_int:"",
    num_ext:"",
    address_id:"",
  }]);

  const handleChangeAddress = name => event => {
    const auxAddress = [...address];
    auxAddress[address.length - 1][name] = event.target.value;
    setAddress(auxAddress);
  };

  const handleChangeSelectAddress = (name,value) => {
    const auxAddress = [...address];
    auxAddress[address.length - 1][name] = value;
    setAddress(auxAddress);
  };


  const estados = [
    'Álvaro Obregón',
    'Azcapotzalco',
    'Benito Juárez',
    'Coyoacán',
    'Cuajimalpa de Morelos',
    'Cuauhtémoc',
    'Gustavo A. Madero',
    'Iztacalco',
    'Iztapalapa',
    'Magdalena Contreras',
    'Miguel Hidalgo',
    'Milpa Alta',
    'Tláhuac',
    'Tlalpan',
    'Venustiano Carranza',
    'Xochimilco'
  ]

  const municipios = [
    'Atizapan De Zaragoza',
    'Chalco',
    'Chimalhuacan',
    'Coacalco',
    'Cuahutitlan Izcalli',
    'Ecatepec',
    'Heroes De Tecamac',
    'Huixquilucan',
    'Isodro Fabela',
    'Ixtapaluca',
    'Jilotzingo',
    'La Paz',
    'Naucalpan',
    'Nezahualcoyolt',
    'Nicolas Romero',
    'Tlanepantla',
    'Toluca',
    'Tultitlan',
    'Valle De Chalco',
    'Otro',
  ]

  useEffect(() => {
    dispatch(actionsUser.fetchUserAddress({user_id: userAuth.user.user_id}));
    dispatch(actionsUser.fetchUserReport(userAuth.user.user_id));
  },[])

  return (
    <div className="row">
      <Modal v-if={isModalVisible} title="Editar dirección" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <form noValidate autoComplete="off">
          <div className="row">
            <div className="col-12">
              <Select
                  className={classes2.textField}
                  style={{width: '100%', marginTop: '10px'}}
                  placeholder={"Estado"}
              >
                <Option value={'CDMX'}>
                  CDMX
                </Option>
                <Option value={'Estado de México'}>
                  Estado de México
                </Option>
                <Option value={'Queretaro'}>
                  Queretaro
                </Option>
                <Option value={'Morelia'}>
                  Morelia
                </Option>
                <Option value={'Cuernavaca'}>
                  Cuernavaca
                </Option>
              </Select>
              {
                values.state === 'CDMX' || values.state === 'Estado de México' ? <Select
                        onSelect={(value) => {
                          handleChangeSelectAddress('city',value)
                        }}
                        className={classes2.textField}
                        style={{width: '100%', marginTop: '10px'}}
                        placeholder={values.state === 'CDMX' ? 'Seleccione Alcaldía' : 'Seleccione Municipio'}
                    >
                      {(values.state === 'CDMX' ? estados : municipios).map((estado) => {
                        return <Option value={estado}>
                          {estado}
                        </Option>
                      })}
                    </Select>
                    : <TextField
                        id="outlined-name"
                        label="Municipio/alcaldia"
                        className={classes2.textField}
                        value={address[address.length - 1].city}
                        style={{width: '100%'}}
                        onChange={handleChangeAddress('city')}
                        margin="normal"
                        variant="outlined"
                    />
              }

              <TextField
                  id="outlined-name"
                  label="Codigo Postal"
                  className={classes2.textField}
                  value={address[address.length - 1].postal_code}
                  onChange={handleChangeAddress('postal_code')}
                  margin="normal"
                  variant="outlined"
              />
              <TextField
                  id="outlined-name"
                  label="Colonia"
                  className={classes2.textField}
                  value={address[address.length - 1].colony}
                  onChange={handleChangeAddress('colony')}
                  margin="normal"
                  variant="outlined"
              />
              <TextField
                  id="outlined-name"
                  label="Calle"
                  className={classes2.textField}
                  value={address[address.length - 1].street}
                  onChange={handleChangeAddress('street')}
                  margin="normal"
                  variant="outlined"
              />

              <TextField
                  id="outlined-name"
                  label="Numero interno"
                  className={classes2.textField}
                  value={address[address.length - 1].num_int}
                  onChange={handleChangeAddress('num_int')}
                  margin="normal"
                  variant="outlined"
              />
              <TextField
                  id="outlined-name"
                  label="Numero externo"
                  className={classes2.textField}
                  value={address[address.length - 1].num_ext}
                  onChange={handleChangeAddress('num_ext')}
                  margin="normal"
                  variant="outlined"
              />
            </div>
          </div>
        </form>
      </Modal>
      <div className="col-md-4 col-12">
        <div className="card card-custom">
          <div className="card-body photo-card">
            <img style={{marginBottom:'45px', marginTop:'20px', border:'none', borderRadius:'0'}} src="/media/logos/logo-light.png" />
            <div>
              <h6> Fecha alta: </h6>
              <p> { userData.createdAt } </p>
              <h6> Ventas del último mes: </h6>
              <p> { currentMonthTotal } </p>
              <h6> Nivel de socio: </h6>
              <p> { userData.Type_user.label } </p>
            </div>
          </div>
        </div>
        <div className="card card-custom">
          <div className="card-header info-title editable-info">
            <h5>Datos Bancarios</h5>
          </div>
          <div className="card-body info-card">
            <form noValidate autoComplete="off">
              <div className="row">
                <div className="col-xsl-12">
                  <label>Introduzca Nombre del Cuentahabiente:</label>
                  <input type="password" id="Nombres" name="Nombres" />
                  <label>Introduzca Entidad Bancaria</label>
                  <input type="password" id="Nombres" name="Nombres" />
                  <label>Introduzca Número de Cuenta</label>
                  <input type="password" id="Nombres" name="Nombres" />
                  <label>Introduzca Número de Tarjeta</label>
                  <input type="password" id="Nombres" name="Nombres" />
                  <label>Introduzca CLABE</label>
                  <input type="password" id="Nombres" name="Nombres" />
                  <form id='compDom' style={{margin:'10px 0', display: 'none'}} method="post" action={`https://hbplat.serv-lab.com/api/private/uploadReceipt/`}>
                    <input  accept="image/*" type="file" id="myFile" name="imagen"/>
                    <input type="submit"  className="btn btn-primary" />
                  </form>
                  <button className="btn btn-info font-weight-bolder font-size-sm mr-3">Subir INE</button>
                  <form id='ine' style={{margin:'10px 0', display: 'none'}} method="post" action={`https://hbplat.serv-lab.com/api/private/uploadReceipt/`}>
                    <input accept="image/*" type="file" id="myFile" name="imagen" />
                    <input type="submit"  className="btn btn-primary" />
                  </form>
                  <button className="btn btn-info font-weight-bolder font-size-sm mr-3">Subir Comprobante de domicilio</button>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="col-md-4 col-12">
        <div className="card card-custom">
          <div className="card-header info-title editable-info">
            <h5> Información del usuario </h5>
          </div>
          <div className="card-body info-card">


            <label for="Nombres">Nombre Completo:</label>
            <input placeholder={userData.name} type="text" id="Nombres" name="Nombres" />

            <div className="input-apellidos">
              <div className="paterno">
                <label for="apellido_1">Ap. Paterno:</label>
                <input placeholder={userData.last_name_1} type="text" id="apellidos" name="apellido_1" />
              </div>
              <div className="materno">
                <label for="apellido_2">Ap. Materno:</label>
                <input placeholder={userData.last_name_2} type="text" id="apellidos" name="apellido_2" />
              </div>
            </div>

            <label for="fechaNacimiento">Fecha de Nacimiento:</label>
            <input placeholder={userData.birthday} type="text" id="fechaNacimiento" name="fechaNacimiento" />

            <label for="genero">Género:</label>
            <input placeholder={userData.genre} type="text" id="genero" name="genero" />

            <label for="telefono">Teléfono:</label>
            <input placeholder={userData.phone} type="text" id="telefono" name="telefono" />

            <label for="correo">Correo Electrónico:</label>
            <input placeholder={userData.email} type="text" id="correo" name="correo" />


            <label for="ciudad">Ciudad:</label>
            <input placeholder={userData.region} type="text" id="ciudad" name="ciudad" />

            <button type="button">Guardar Cambios</button>

          </div>
        </div>
        <div className="card card-custom">
          <div className="card-header info-title editable-info">
            <h5>Cambio de contraseña</h5>
          </div>
          <div className="card-body info-card">
            <form noValidate autoComplete="off">
              <label>Nueva Contraseña:</label>
              <input type="password" autocomplete="false" />
              <label>Confirmar contraseña:</label>
              <input type="password"  autocomplete="false" />

              <button type="button">Guardar contraseña</button>
            </form>
          </div>
        </div>
      </div>

      <div className="col-md-4 col-12">
        <div className="card card-custom">
          <div className="card-header info-title">
            <h5>Direcciones</h5>
          </div>
          <div className="card-body">
            {
                currentListAddress &&
                currentListAddress.map((item, ind) => {
                  return (
                      <div
                          key={ind + "st1"}
                          style={{
                            margin: '25px 0',
                            padding: '25px 10px',
                            border: '1px',
                            borderStyle: 'dotted',
                            borderColor: 'black',
                          }}
                          onClick={showModal}
                          className="selected-address">
                                                                    <span style={{
                                                                      color: "black",
                                                                    }}
                                                                          className="mt-3 font-weight-bold font-size-sm">
                                                                        Dirección {ind + 1}
                                                                      <br/>
                                                                    </span>
                        {item.Address.street + ' num.' + item.Address.num_ext + ' ' + item.Address.num_int}
                        <br/>
                        {item.Address.colony}
                        <br/>
                        {item.Address.city + ' ' + item.Address.postal_code}
                        <br/>
                        {item.Address.state}
                        <br/>
                      </div>
                  )

                })
            }

          </div>

        </div>
        <div className="card card-custom" style={{ marginTop: '15px'}}>
          <div className="card-header info-title">
            <h5>Equipo</h5>
          </div>
          <div className="card-body">
            { dataUsers.report && dataUsers.report.teamData &&
                <div className="order-1 order-xxl-2">
                  <ListsWidget14 data={dataUsers.report && dataUsers.report.teamData} className="card-stretch gutter-b"/>
                </div>
            }
          </div>
        </div>
      </div>

    </div>
  );
}
