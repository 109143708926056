import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { CustomersFilter } from "./customers-filter/CustomersFilter";
import { CustomersTable } from "./customers-table/CustomersTable";
import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";
import { useCustomersUIContext } from "./CustomersUIContext";
import * as actions from "../../_redux/customers/customersActions";
import {useDispatch} from "react-redux";

export function CustomersCard() {
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
      downloadCustomerButtonClick: customersUIContext.downloadCustomerButtonClick,
    };
  }, [customersUIContext]);
  const dispatch = useDispatch();
  const getCSV = () =>{
    dispatch(actions.fetchCustomersAdminCSV(customersUIProps.queryParams));
  }


  return (
    <Card>
      <CardHeader title="Lista de clientes">
        <CardHeaderToolbar>
          <button
            type="button"
            style={{marginRight:'15px'}}
            className="btn btn-primary"
            onClick={getCSV}
          >
            Descargar Clientes CSV
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={customersUIProps.newCustomerButtonClick}
          >
            Agregar Cliente
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/*<CustomersFilter />*/}
        {/*{customersUIProps.ids.length > 0 && <CustomersGrouping />}*/}
        <CustomersTable />
      </CardBody>
    </Card>
  );
}
